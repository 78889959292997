import React from "react";
import { useDropzone } from "react-dropzone";

import { MdOutlineFileUpload } from "react-icons/md";


const FileUploadModal = ({ isOpen, onClose, onFileSelect }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        onFileSelect(acceptedFiles[0]);
        onClose();
      }
    },
  });

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
        onClose();
    }
};

  if (!isOpen) return null;

  return (
    <div onClick={handleOverlayClick} className="fixed inset-0 bg-black bg-opacity-50 z-30 flex justify-center items-center">
      <div className="bg-[#E9EFEC] p-5 rounded-lg shadow-lg w-5/6 sm:w-1/2">
        <div className="flex flex-col gap-4">
          <div
            {...getRootProps()}
            className="border-dashed border-2 border-gray-400 p-10 flex-1 flex flex-col justify-center items-center cursor-pointer"
          >
            <input {...getInputProps()} />
            <div className="flex flex-col items-center gap-8">
              <p className="w-12 h-12 text-[48px] text-[#658DF1]"><MdOutlineFileUpload /></p>
              <div className="text-center">
                <p className="text-lg font-medium text-black">Drag & Drop your file here</p>
                <p className="text-xs font-semibold text-[#3F4946]">Files supported: PDF, JPEG, PNG</p>
              </div>
            </div>
          </div>
          <div className="seprator-or flex justify-center items-center">
            <hr className="h-[2.5px] w-[30%] bg-[#6F7976]" />
            <p className="mx-2 text-2xl font-medium text-black">OR</p>
            <hr className="h-[2.5px] w-[30%] bg-[#6F7976]" />
          </div>
          <div className="flex-1 flex flex-col justify-center items-center">
            <label htmlFor="file-selector-upload-modal" className="text-center">
              <p className="text-sm font-roboto font-medium text-[#001848] px-6 py-[10px] bg-[#DAE2FF] rounded-full">Choose File</p>
              <p className="text-xs font-semibold text-[#3F4946]">Maximum size: 10 MB</p>
            </label>
            <input
              type="file" id="file-selector-upload-modal"
              className="hidden"
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  onFileSelect(e.target.files[0]);
                  onClose();
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploadModal;
