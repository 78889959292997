import { useState,useEffect } from "react";
import axios from "axios";

import { useAuth } from "../../../../../contexts/AuthContext";

const PersonalDetailsForm = ({step, nextStep}) => {

    const {userData} = useAuth();

    const [PDvalue, setPDvalue] = useState({
        full_name:"",
        mobile:"",
        email:"",
        gender:"",
        language:"",
        occupation:"",
        dob:"",
        postal_address:"",
        emergency_contact:""
    });

    useEffect(()=>{
        if(!userData) return;
        axios.post("/api/patient/profile/get-personal-details", {patient_id:userData.patient_id})
        .then(res=>{
            setPDvalue({...res.data.details});
        })
        .catch(err=>{
            console.error(err);
        });
    },[userData]);

    const handlePDInput = (e)=>{
        setPDvalue({...PDvalue,[e.target.name]:e.target.value})
    }

    const handlePDSubmit = async (e)=>{
        e.preventDefault();
        const pd = await axios.post("/api/patient/profile/personal-details", PDvalue);
        console.log(pd.data);
        nextStep();
    }

    return(
        <>  
        {step === 1 && (
            <form onSubmit={handlePDSubmit} className="lg:px-56">
                <div className="grid grid-flow-col gap-4 lg:justify-between lg:grid-rows-5 grid-rows-9 lg:gap-5 ">
                    <div className="mb-4">
                        <label htmlFor="full_name" className="block text-base font-medium text-[#061D2E]">Full Name <span className="text-base font-normal text-[#B3261E]">*</span></label>
                        <input  type="text" id="full_name" name="full_name" onChange={handlePDInput} value={PDvalue.full_name || ''} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Enter your full name"/>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="dob" className="block text-base font-medium text-[#061D2E]">Date of Birth <span className="text-base font-normal text-[#B3261E]">*</span></label>
                        <input  type="date" id="dob" name="dob" onChange={handlePDInput} value={PDvalue.dob || ''} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Enter your mobile number"/>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="gender" className="block text-base font-medium text-[#061D2E]">Gender <span className="text-base font-normal text-[#B3261E]">*</span></label>
                        <select  id="gender" name="gender" onChange={handlePDInput} value={PDvalue.gender || ''} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01">
                            <option value="">Select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="occupation" className="block text-base font-medium text-[#061D2E]">Occupation <span className="text-base font-normal text-[#B3261E]">*</span></label>
                        <input  type="text" id="occupation" name="occupation" onChange={handlePDInput} value={PDvalue.occupation || ''} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Enter Your Occupation"/>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="language" className="block text-base font-medium text-[#061D2E]">Prefered Language <span className="text-base font-normal text-[#B3261E]">*</span></label>
                        <select  id="language" name="language" onChange={handlePDInput} value={PDvalue.language || ''} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01">
                            <option value="">Select</option>
                            <option value="tamil">Tamil</option>
                            <option value="telungu">Telungu</option>
                            <option value="hindi">Hindi</option>
                            <option value="english">English</option>
                            <option value="kannada">Kannada</option>
                            <option value="malayalam">malayalam</option>
                            <option value="odiya">Odiya</option>
                            <option value="urdu">Urdu</option>
                            <option value="gujarati">Gujarathi</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="mobile" className="block text-base font-medium text-[#061D2E]">Mobile Number <span className="text-base font-normal text-[#B3261E]">*</span></label>
                        <input  type="tel" id="mobile" name="mobile" onChange={handlePDInput} value={PDvalue.mobile || ''} required disabled className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" pattern="[0-9]{10}" placeholder="Enter your mobile number"/>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-base font-medium text-[#061D2E]">Email ID <span className="text-base font-normal text-[#B3261E]">*</span></label>
                        <input  type="email" id="email" name="email" onChange={handlePDInput} value={PDvalue.email || ''} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="example@gmail.com"/>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="postal_address" className="block text-base font-medium text-[#061D2E]">Postal Address <span className="text-base font-normal text-[#B3261E]">*</span></label>
                        <input  type="text" id="postal_address" name="postal_address" onChange={handlePDInput} value={PDvalue.postal_address || ''} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Enter Your Postal Address"/>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="emergency_contact" className="block text-base font-medium text-[#061D2E]">Emergency Contact number <span className="text-base font-normal text-[#B3261E]">*</span></label>
                        <input  type="text" id="emergency_contact" name="emergency_contact" onChange={handlePDInput} value={PDvalue.emergency_contact || ''} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" pattern="[0-9]{10}" placeholder="Enter Your Emergency Contact Number"/>
                    </div>
                </div>

                <div className="justify-between flex">
                    <button type="button" className="lg:px-[70px] px-[44px] py-4 border border-gray-400 text-sm font-medium font-roboto text-[#006B5B] rounded-[100px]">
                        Edit
                    </button>
                    <button type="submit" className="lg:px-[70px] px-[44px] py-4 bg-[#006B5B] text-sm font-medium font-roboto text-white rounded-[100px]">
                        Next
                    </button>
                </div>
            </form>
        )}
        </>
    )
}

export default PersonalDetailsForm;