import { useEffect } from "react";
import {useNavigate} from "react-router-dom";

import { useAuth } from "../../../../contexts/AuthContext";

//Stepper form for the Personal details
import PersonalStepperForm from "./personal_details_forms/PersonalStepperForm";

const PersonalDetails = () => {
  const navigate = useNavigate();
  const {isAuthenticated, isLoading} = useAuth();

  useEffect(()=>{
    if(!isLoading){
      if(!isAuthenticated){
        navigate("/doctor/home");
      }else {console.log("loged in")}
    }
  },[])

  return (
    <>
      <div className="w-full lg:mt-24 lg:mx-24 mx-1">
        <PersonalStepperForm />
      </div>
    </>
  );
};

export default PersonalDetails;
