import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";

import {
    MdDashboardCustomize,
    MdForwardToInbox,
    MdCalendarMonth,
    MdAnalytics,
    MdArticle,
    MdSettings,
    MdLogout ,
  } from "react-icons/md";

const SideNav = () =>{
  const navigate = useNavigate();
  const {logout} = useAuth();
  function handleLogout(){
    logout();
    navigate("/doctor/home");
  }

    return(
        <>
        <div className="max-lg:hidden bg-[#EEF0FF] h-screen w-[82px] flex flex-col justify-between text-center gap-4 py-16">
          <div className="mt-3">
            <p className="rounded-full mx-auto h-[72px] w-[72px] text-3xl text-white p-4 bg-[#658DF1]">
              RV
            </p>
          </div>
          <div>
            <ul className="flex flex-col gap-5">
              <li className="text-sm">
                <span href="/">
                  <MdDashboardCustomize className="text-2xl text-[#006B5B] mx-auto" />
                  <p>Dashboard</p>
                </span>
              </li>
              <li className="text-sm">
                <span href="/">
                  <MdForwardToInbox className="text-2xl text-[#006B5B] mx-auto" />
                  <p>Inbox</p>
                </span>
              </li>
              <li className="text-sm">
                <span href="/">
                  <MdCalendarMonth className="text-2xl text-[#006B5B] mx-auto" />
                  <p>Calendar</p>
                </span>
              </li>
              <li className="text-sm">
                <span href="/">
                  <MdAnalytics className="text-2xl text-[#006B5B] mx-auto" />
                  <p>Calendar</p>
                </span>
              </li>
              <li className="text-sm">
                <span href="/">
                  <MdArticle className="text-2xl text-[#006B5B] mx-auto" />
                  <p>Calendar</p>
                </span>
              </li>
            </ul>
          </div>
          <div>
            <ul className="flex flex-col gap-5">
              <li className="text-sm">
                <span href="/">
                  <MdSettings className="text-2xl text-[#006B5B] mx-auto" />
                  <p>Setting</p>
                </span>
              </li>
              <li className="text-sm" onClick={handleLogout}>
                <span>
                  <MdLogout  className="text-2xl text-[#006B5B] mx-auto" />
                  <p>Logout</p>
                </span>
              </li>
            </ul>
          </div>
        </div>
        </>
    )
}

export default SideNav;