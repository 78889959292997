import { NetworkErrorSwal,DuplicateErrorSwal,UnknownErrorSwal } from "../pages/patient/components/swal-alert/Errors";

function handleErrorAlert(err) {
    if (err.code === "ERR_NETWORK") {
        NetworkErrorSwal(err);
    } else if (err.code === "ERR_BAD_REQUEST") {
        DuplicateErrorSwal(err);
    } else {
        UnknownErrorSwal(err);
    }
}

export default handleErrorAlert;