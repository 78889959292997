import { useState,useEffect } from 'react';
import axios from "axios";

import {useAuth} from "../../../../../contexts/AuthContext";

//state json file
import stateList from "../../../../../data/stateList.json";

//Import an Image
import ph_hospital from '../../../asserts/personal-details/ph_hospital.svg';

const ClinicDetails = ({step, nextStep}) => {

    const [inpData, setInpData] = useState({clinic_name:"",door_no:"",street_name: "",state: "",city: "",zipcode: "",consultation_fee:""});
    const {userData} = useAuth();

    useEffect(()=>{
        if (!userData) return;
        axios.post("/api/doctor/personal/get-clinic-details",{doctor_id:userData._id})
        .then(res=>{
            if(res.data.status){setInpData(res.data.details)}
        })
        .catch(err=>console.log(err))
    },[])


    function handleInput(e){
        setInpData({...inpData,[e.target.name]:e.target.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const clinicData = {...inpData,doctor_id:userData._id};
            console.log(clinicData);
            const response = await axios.post('/api/doctor/personal/clinic-details', clinicData);
            console.log(response.data);
            await nextStep();
        } catch (err) {
            console.error(err);
        }
    };

    return(
        <>
        {step === 1 && (
            <form className='max-lg:mb-10 lg:my-14' onSubmit={handleSubmit}>
            <div className="flex flex-col sm:flex-row w-full">
                <div className=" p-5 w-full">
                    <div className="mb-4 lg:mr-56">
                        <div className='flex flex-row justify-between'>
                            <label htmlFor="clinic_name" className="block text-base font-medium text-[#061D2E]">Hospital/ Clinic Name <span className="text-base font-normal text-[#B3261E]">*</span></label>
                            <div className=''><p className='text-[6.75px] font-semibold bg-gray-100 rounded-full italic p-1'> &nbsp; i &nbsp;</p></div>
                        </div>
                        <input type="text" id="clinic_name" name="clinic_name" value={inpData.clinic_name} onChange={handleInput} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Enter your clinic name"/>
                    </div>
                    <div className="mb-4">
                        <label className="block text-base font-medium text-[#061D2E]">Hospital/ Clinic Address <span className="text-base font-normal text-[#B3261E]">*</span></label>
                        <input type="text" id="door_no" name="door_no" value={inpData.door_no} onChange={handleInput} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Door No./ Flat name"/>
                        <input type="text" id="street_name" name="street_name" value={inpData.street_name} onChange={handleInput} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Street Name"/>
                        <select id="state" name="state" value={inpData.state} onChange={handleInput} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01">
                            <option value="">State</option>
                            {stateList.map((data,idx)=>(
                                <option key={idx} value={data.state_id}>{data.state_name}</option>
                            ))}
                        </select>
                        <input type="text" id="city" name="city" value={inpData.city} onChange={handleInput} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="City"/>
                        <input type="text" id="zipcode" name="zipcode" value={inpData.zipcode} onChange={handleInput} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Zip code"/>
                    </div>
                    <div className="mb-4 lg:mr-56">
                        <div className='flex flex-row justify-between'>
                            <label htmlFor="consultation_fee" className="block text-base font-medium text-[#061D2E]">Consultation Fees <span className="text-base font-normal text-[#B3261E]">*</span></label>
                        </div>
                        <div className="flex gap-1 lg:w-[320px]">
                            <label htmlFor="consultation_fee" className="h-12 w-[70px] text-sm text-[#757776] font-medium mt-2 p-[15.5px_20px_15.5px_12px] block border-[0.4px] border-[#6F7976] rounded shadow-input-01">
                                ₹
                            </label>
                            <input type="text" id="consultation_fee" name="consultation_fee" value={inpData.consultation_fee} onChange={handleInput} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Ex: 400"/>
                        </div>
                        <p className='font-medium text-xs text-[#757776]'>
                            The amount you enter here will be displayed to others during  appointment booking.
                        </p>
                    </div>
                </div>
                <div className="h-auto w-full lg:block hidden">
                    <div className='bg-[#A0F2DE29] md:w-[480px] flex flex-col gap-8 px-3 py-6 rounded-lg shadow-lg shadow-[#006B5B33]'>
                        <div className='flex justify-center'>
                            <img src={ph_hospital} alt='hospital svg'/>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <p className='text-3xl font-bold'>Tell us about your Clinic</p>
                            <p className='text-base'>Let's start with the basics.<br></br>Please provide the name of your clinic and its address in detail.</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="flex justify-start lg:justify-end">
                <button type="submit" className="px-[70px] py-4 bg-blue-500 text-sm font-medium font-roboto text-white rounded-[100px]">
                  Save & Continue
                </button>
              </div>
            </form>
          )}
        </>
    )
}

export default ClinicDetails;