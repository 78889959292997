
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import { Grid, Pagination } from 'swiper/modules';

import searchForaDoctor from "../asserts/home/search-icon-container.png";
import chooseAppointment from "../asserts/home/Choose-Appointment-Type-icon.png";
import bookAppointment from "../asserts/home/Book-Appointment-icon.png";
import consultationWithDoctor from "../asserts/home/Consultation-with-Doctor.png";
import prescriptions from "../asserts/home/Prescriptions-icon.png";
import followUp from "../asserts/home/Follow-Up-icon.png";



function HowItWorks(){
    const works = [
                    {
                        img: searchForaDoctor,
                        title: "1. Search for a Doctor",
                        desc: "Find the right doctor based on specialities or medical conditions"
                    },
                    {
                        img: chooseAppointment,
                        title: "2. Choose Appointment Type",
                        desc: "Select between video call consultations or in-clinic visits"
                    },
                    {
                        img: bookAppointment,
                        title: "3. Book Appointment",
                        desc: "Schedule a convenient date and time for your consultation"
                    },
                    {
                        img: consultationWithDoctor,
                        title: "4. Consultation with Doctor",
                        desc: "Connect with the doctor through video call or visit the clinic for your appointment"
                    },
                    {
                        img: prescriptions,
                        title: "5. Diagnosis from Doctor",
                        desc: "Receive prescriptions and medical recommendations from the doctor"
                    },
                    {
                        img: followUp,
                        title: "6. Follow-Up & Feedback",
                        desc: "Get follow-up consultations and provide feedback on your experience"
                    },
    ]

    return(
        <section className="HowItWorks w-screen h-[820px] px-6 py-5 bg-[#FFFDF9] 
        sm:h-[1050px] sm:p-[64px_35px_64px_35px] sm:bg-[#A0F2DE14]
        md:h-fit
        2xl:h-fit 2xl:bg-[#FFFDF9] 2xl:p-[64px_192px_64px_192px]">
            <div className="grid grid-cols-1 gap-5 sm:gap-12 lxl:w-[1056px] lxl:mx-auto">
                <h1 className="text-2xl font-bold text-center text-[#004135] sm:text-4xl sm:leading-[44px] 2xl:text-[54px] 2xl:leading-[72px]">
                    How doctor consultation <span className="text-[#228573]">works</span> 
                </h1>
                <div className="steps-container md:h-[912px] lg:h-[572px]">
                    <WorkSlider worksList={works} />
                </div>
            </div>
        </section>
    );
}


function WorkSlider(props) {

    return (
        <div className='h-[572px]'>
            <Swiper
            className="how-it-works-swiper w-[312x] h-full sm:w-full sm:h-[912px] md:h-[912px] lg:h-[572px] 2xl:w-[1056px]"
            slidesPerView={1}
            grid={{ rows: 2, }}
            spaceBetween={10}
            pagination={{ clickable: true, }}
            modules={[Grid, Pagination]}
            breakpoints={{
                640: {
                    slidesPerView: 1,
                    grid: { rows: 2,},
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2,
                    grid: { rows: 3,},
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 3,
                    grid: { rows: 2,},
                    spaceBetween: 10,
                },
            }}
            >
                {
                    props.worksList.map((data,idx)=>(
                        <SwiperSlide key={idx} className='w-[302px_!important]'>
                            <div className="w-[302px] h-[254px] rounded-[20px] relative">
                                <div className="w-[302px] h-[216px] rounded-[20px] flex justify-center items-end p-[80px_24px_32px_24px]">
                                    <div className="img absolute top-0 z-10">
                                        <img src={data.img} alt={data.title} />
                                    </div>
                                    <div className="content bg-white text-center h-[192px] rounded-[20px] p-[80px_24px_32px_24px] border border-[#E0E0E0] grid gap-2 absolute bottom-0">
                                        <h5 className="text-lg font-medium text-DarkBlue">{data.title}</h5>
                                        <p className="text-base font-normal text-[#454746]">{data.desc}</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
}


export default HowItWorks;
