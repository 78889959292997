import { useState, useEffect } from "react";
import { useParams,Link } from "react-router-dom";
import axios from "axios";

import { MdOutlineLocationOn } from "react-icons/md";

import {getADoc} from "../../../doctor/DoctorsList";

//state list json data
import stateList from "../../../../../data/stateList.json";

import WorkPlace from "../../../asserts/doctors/WorkPlace.png";


function AboutTabContent(){
    const {dID} = useParams();

    const [doc, setDoc] = useState([]); 
    const [clinicDetails, setClinicDetails] = useState({});

    const getDoc = async ()=>{
        const d = await getADoc(dID);
        setDoc(d);
    }

    const getClinicDetails = async ()=>{
        const cd = await axios.post("/api/doctor/personal/get-clinic-details", {doctor_id: dID})
        if(cd.data.status){
            setClinicDetails(cd.data.details);
        }
    }

    useEffect(()=>{
        getDoc();
        getClinicDetails();
    },[])

    return(
        <div className="flex flex-col gap-4">
            <Specializes doc={doc} />
            <WorksAt clinicDetails={clinicDetails} />
            <DoctorInfo />
        </div>
    );
}


function Specializes({doc}) {

    return(
        <div className="bg-[#066B5B0D] grid gap-3 p-2">
            <h2 className="text-2xl font-medium">Specializes in</h2>
            <div className="language pt-2 flex justify-start gap-2">
                {doc?.tags?.map((expert,Eidx)=>(
                    <p key={Eidx} className="bg-[#DAE2FF] rounded py-1 px-2 font-medium text-sm text-[#001848] font-roboto">
                        {expert.label}
                    </p>
                ))}
            </div>
        </div>
    )
}


function WorksAt({clinicDetails}) {

    const getStateName = (sid)=>{
        const state = stateList.find(s => s.state_id === sid);
        return state ? state.state_name : 'Unknown';
    }

    return(
        <div className="bg-[#066B5B0D] grid gap-4 p-[8px_2px_0px_2px]">
            <h2 className="text-2xl font-medium">Works At</h2>
            <div className="flex justify-between items-center pr-2">
                <div className="flex gap-5">
                    <img src={WorkPlace} alt={clinicDetails.clinic_name} className="w-[100px] h-[100px] rounded" />
                    <div className="grid gap-2">
                        <h3 className="text-lg font-medium">{clinicDetails.clinic_name}</h3>
                        <div className="grid gap-1">
                            <p className="text-sm font-normal">{clinicDetails.street_name}, {clinicDetails.city}</p>
                            <p className="text-sm font-normal">{getStateName(clinicDetails.state)}</p>
                            <p className="flex items-center text-sm font-normal"> <MdOutlineLocationOn /> <Link to={'#'} className="text-[#2C59BA] underline"> Get Directions</Link></p>
                        </div>
                    </div>
                </div>
                
                <h2 className="p-2 text-xl font-semibold">9 AM - 12 PM</h2>
            </div>
        </div>
    )
}


function DoctorInfo() {
    const DoctorInfoList = [
        {
            title:"Education",
            description:"[University Name], [Degree Earned], [Year of Graduation"
        },
        {
            title:"Specialty",
            description:"[Specialization/Area of Expertise]"
        },
        {
            title:"Achievements",
            description:"[Any notable achievements, awards, or recognitions]"
        },
        {
            title:"Experience",
            description:"With [X years] of experience in [Specialization/Area of Expertise], Dr. [Last Name] has demonstrated a commitment to patient well-being and a passion for delivering high-quality healthcare services."
        },
        {
            title:"Memberships",
            description:"[List of relevant professional memberships, if applicable]"
        },
    ]
    return(
        <div className="bg-[#066B5B0D] p-[8px_10px_8px_10px] grid gap-4">
            <h2 className="text-2xl font-medium">Doctor Info</h2>
            <div className="grid gap-4">
                {
                    DoctorInfoList.map((data,idx)=>(
                        <div key={idx} className="px-0 py-1 flex flex-col gap-3">
                            <h3 className="w-fit px-3 py-[6px] bg-[#A0F2DE] rounded-sm text-base font-semibold">{data.title}</h3>
                            <p className="test-sm font-normal">{data.description}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}


export default AboutTabContent;