
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import { Grid, Pagination } from 'swiper/modules';

import ChildCare from '../asserts/home/ChildCare.png'
import SkinCare  from '../asserts/home/SkinCare.png'
import Fever from '../asserts/home/FeverIcon.png'
import MentalHealth  from '../asserts/home/MentalHealth.png'
import Bones from '../asserts/home/Bones.png'
import Stomach from '../asserts/home/Stomach.png'

function TypesOfIssues(){

    const issues = [
        {
            title: "Child care",
            img: ChildCare,
        },
        {
            title: "Skin care",
            img: SkinCare,
        },
        {
            title: "Fever/cold/cough",
            img: Fever,
        },
        {
            title: "Mental health",
            img: MentalHealth,
        },
        {
            title: "Bone & Muscle",
            img: Bones,
        },
        {
            title: "Stomach care",
            img: Stomach,
        },
    ];

    return(
        <section className="bg-white w-[89.16%] h-[365px] mx-auto my-8 flex flex-col gap-[31px]
        sm:py-20 sm:px-[34px] sm:gap-[70px] sm:h-[858px] sm:w-screen
        xl:h-[540px]
        2xl:p-[80px_111px_80px_111px] 2xl:m-0 2xl:h-[540px] 2xl:w-screen">
            <div>
                <h1 className="text-start text-2xl text-[#004135] font-medium 
                sm:text-center sm:text-[36px] sm:leading-[52px] sm:font-bold sm:whitespace-nowrap
                lmd:text-[40px]
                2xl:text-[54px] 2xl:leading-[72px]">
                    Consult experts for any <span className="text-[#228573]">health concern</span>
                </h1>
            </div>

            <div className="issues">
                <IssueSlider issuesList={issues}/>
            </div>
        </section>
    )
}



function IssueSlider(props) {

    return (
        <div className="">
            <Swiper
            className="h-[270px] md:h-[576px] xl:h-[238px]"
            slidesPerView={2}
            grid={{ rows: 1, }}
            spaceBetween={10}
            pagination={{ clickable: true, }}
            modules={[Grid, Pagination]}
            breakpoints={{
                768: {
                    slidesPerView: 3,
                    grid: { rows: 2,},
                    spaceBetween: 30
                },
                1280: {
                    slidesPerView: 6,
                    grid: { rows: 1,},
                    spaceBetween: 30
                }
            }}
            >
                {
                    props.issuesList.map((data,idx)=>(
                        <SwiperSlide key={idx}>
                            <div className="flex flex-col gap-6 justify-center items-center">
                                <img src={data.img} alt={data.title} className='w-[110px] h-[110px]' />
                                <div className="content grid gap-4">
                                    <p className="text-lg 2xl:text-xl text-[#0C3D5B] text-center font-medium">{data.title}</p>
                                    <button className="h-[40px] w-[141px] border-[#228573] border py-2 px-5 rounded-[27px] text-sm text-[#228573] font-bold 2xl:w-[161px] 2xl:text-base">Consult Now</button>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
}



export default TypesOfIssues