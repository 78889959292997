import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import { useAuth } from '../../../../contexts/AuthContext';

import StepperForm from './registration_details_forms/StepperForm';

function RegistrationDetails(){

  const navigate = useNavigate();
  const {isAuthenticated, isLoading,userData} = useAuth();
  
  // useEffect(()=>{
  //   if(!isLoading){
  //     if(!isAuthenticated){
  //       navigate("/doctor/home");
  //     }else {console.log("loged in")}
  //   }
  // },[isAuthenticated,isLoading])


const[isFormFilled,setIsFormFilled] = useState(false);
  // useEffect(()=>{
  //   if(!userData) return;
  //   axios.get("/api/doctor/registration/check-register/" + userData._id)
  //       .then(res => {
  //           console.log(res.data)
  //           setIsFormFilled(res.data.status)
  //       })
  //       .catch(error => {
  //           console.log(error);
  //       });
  // },[])

  // if (isLoading) {
  //   return <div>Loading...</div>; // Show loading indicator while checking authentication
  // }
  return(
    <div className="bg-white w-screen h-screen lg:mt-20 lg:mx-36 gap-2 flex flex-col px-4">
        {!isFormFilled && <h1 className="w-[70%] text-2xl font-bold text-[#1D2366] lg:text-black lg:text-3xl">Congratulations on your Sign up!</h1>}
        <div className="clear-both lg:hidden h-6 bg-transparent"></div>
        <p className='text-sm font-normal text-[#001847] lg:hidden'>Fill the details for complete set-up</p>
        <div className="clear-both lg:hidden h-2 bg-transparent"></div>

        <StepperForm />

    </div>
  );
}


export default RegistrationDetails;