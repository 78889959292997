import React, { useState,useEffect } from 'react';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';

import { useAuth } from '../../../../../contexts/AuthContext';

import ph_hospital from '../../../asserts/personal-details/ph_hospital.svg';

const PracticeDetails = ({ step, nextStep }) => {

    const {userData} = useAuth();

    const [practiceData, setPracticeData] = useState({
        practice: "",
        specialty: "",
        tags: [],
        mode_of_appointment: "",
        experience: "",
        languages: [],
    });


    useEffect(()=>{
        if(!userData) return;
        axios.post("/api/doctor/personal/get-practice-details",{doctor_id:userData._id})
        .then(res=>{
            if(res.data.status){setPracticeData(res.data.details)}
        })
        .catch(err=>console.log(err))
    },[])


    const handleInput = (e) => {
        setPracticeData({ ...practiceData, [e.target.name]: e.target.value });
    };

    const handleTagChange = (newValue) => {
        setPracticeData({ ...practiceData, tags: newValue });
    };
    const handleLanguageTagChange = (newValue) => {
        setPracticeData({ ...practiceData, languages: newValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(practiceData);
        try {
            const practice = {...practiceData,doctor_id:userData._id};
            console.log(practice)
            const response = await axios.post("/api/doctor/personal/practice-details", practice);
            console.log(response.data);
        } catch (err) {
            console.error(err);
        }

        await nextStep();
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '3rem', // equivalent to h-12
            fontSize: '0.875rem', // equivalent to text-sm
            fontWeight: '400', // equivalent to font-normal
            marginTop: '0.5rem', // equivalent to mt-2
            // padding: '15.5px 20px 15.5px 12px',
            width: '100%',
            maxWidth: '320px',
            border: '0.4px solid #6F7976',
            borderRadius: '0.375rem', // equivalent to rounded
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)', // equivalent to shadow-input-01
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
    };

    const tagOptions = [
        { value: 'ayurveda', label: 'Ayurveda' },
        { value: 'naturopathy', label: 'Naturopathy' },
        { value: 'generalmedicine', label: 'General Medicine' }
    ];
    const languageOptions = [
        { value: 'english', label: 'English' },
        { value: 'tamil', label: 'Tamil' },
        { value: 'telugu', label: 'Telugu' },
        { value: 'malayalam', label: 'Malayalam' },
    ]

    return (
        <>
            {step === 2 && (
                <form className="my-10 lg:mr-32" onSubmit={handleSubmit}>
                    <div className="flex flex-col sm:flex-row w-full mt-20">
                        <div className="p-5 w-full">
                            <div className="mb-4">
                                <label htmlFor="practice" className="block text-base font-medium text-[#061D2E]">
                                    Practice <span className="text-base font-normal text-[#B3261E]">*</span>
                                </label>
                                <select
                                    id="practice"
                                    name="practice"
                                    value={practiceData.practice}
                                    onChange={handleInput}
                                    required
                                    className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01"
                                >
                                    <option value="">Select</option>
                                    <option value="ayurveda">Ayurveda</option>
                                    <option value="allopathy">Allopathy</option>
                                    <option value="homeopathy">Homeopathy</option>
                                    <option value="naturopathy">Naturopathy</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="specialty" className="block text-base font-medium text-[#061D2E]">
                                    Specialty <span className="text-base font-normal text-[#B3261E]">*</span>
                                </label>
                                <select
                                    id="specialty"
                                    name="specialty"
                                    value={practiceData.specialty}
                                    onChange={handleInput}
                                    required
                                    className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01"
                                >
                                    <option value="">Select</option>
                                    <option value="ayurveda">Ayurveda</option>
                                    <option value="naturopathy">Naturopathy</option>
                                    <option value="generalmedicine">General Medicine</option>
                                    <option value="gastroenterology">Gastroenterology</option>
                                    <option value="cardiology">Cardiology</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="tags" className="block text-base font-medium text-[#061D2E]">
                                    Add type of illness for your specialty <span className="text-base font-normal text-[#B3261E]">*</span>
                                </label>
                                <CreatableSelect
                                    id="tags"
                                    isMulti
                                    name="tags"
                                    value={practiceData.tags}
                                    onChange={handleTagChange}
                                    className="basic-multi-select"
                                    styles={customStyles}
                                    options={tagOptions}
                                    classNamePrefix="select"
                                    placeholder="Select or create tags"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="mode_of_appointment" className="block text-base font-medium text-[#061D2E]">
                                    Mode of Appointment <span className="text-base font-normal text-[#B3261E]">*</span>
                                </label>
                                <select
                                    id="mode_of_appointment"
                                    name="mode_of_appointment"
                                    value={practiceData.mode_of_appointment}
                                    onChange={handleInput}
                                    required
                                    className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01"
                                >
                                    <option value="">Select</option>
                                    <option value="online">Online</option>
                                    <option value="offline">Offline</option>
                                    <option value="both">Both</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="languages" className="block text-base font-medium text-[#061D2E]">
                                    Languages <span className="text-base font-normal text-[#B3261E]">*</span>
                                </label>
                                <CreatableSelect
                                    id="languages"
                                    isMulti
                                    name="languages"
                                    value={practiceData.languages}
                                    onChange={handleLanguageTagChange}
                                    className="basic-multi-select"
                                    styles={customStyles}
                                    options={languageOptions}
                                    classNamePrefix="select"
                                    placeholder="Select all the languages you know"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="experience" className="block text-base font-medium text-[#061D2E]">
                                    Experience <span className="text-base font-normal text-[#B3261E]">*</span>
                                </label>
                                <input type="number" 
                                    id="experience"
                                    name="experience"
                                    value={practiceData.experience}
                                    onChange={handleInput}
                                    required placeholder='Enter no. of years'
                                    className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01"
                                />
                            </div>
                        </div>
                        <div className="h-auto w-full lg:block hidden">
                            <div className="bg-[#A0F2DE29] md:w-[480px] flex flex-col gap-8 px-3 py-6 rounded-lg shadow-lg shadow-[#006B5B33]">
                                <div className="flex justify-center">
                                    <img src={ph_hospital} alt="hospital svg" />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <p className="text-3xl font-bold">Tell us about your practice</p>
                                    <p className="text-base">
                                        These details help to personalize the patient's search
                                        <br />
                                        Please provide the name of your clinic and its address in detail.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-start lg:justify-end">
                        <button
                            type="submit"
                            className="px-[70px] py-4 bg-blue-500 text-sm font-medium font-roboto text-white rounded-[100px]"
                        >
                            Save & Continue
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};

export default PracticeDetails;
