import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../contexts/AuthContext"

import HeroSection from "./hero_section/HeroSection"
import WhyJoinUs from "./WhyJoinUs"
import CallBackSection from "./CallBackSection"
import FAQ from "./FAQ"

function DoctorHome() {
    const navigate = useNavigate();
    const {isAuthenticated, isLoading, userData} = useAuth();

    useEffect(()=>{
        if(!isLoading){
            if(isAuthenticated && !userData.hasOwnProperty("patient_id")){
                console.log("loginnn")
                navigate("/doctor/dashboard")
            }
        }
    },[isAuthenticated, isLoading])

    if (isLoading) {
        return(
            <div>
                Loading...
            </div>
        )
    }

    return (
        <div className="">
            <HeroSection/>
            <WhyJoinUs/>
            <CallBackSection/>
            <FAQ />
        </div>
    )
}

export default DoctorHome