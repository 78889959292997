import { useState } from "react";

import TDL from "./TabsDetailsList";

import "./DoctorOptionsTab.css";

function DoctorOptionsTab() {

    const [activeIndex, setActiveIndex] = useState(1);


    const handleTabClick = (idx)=>setActiveIndex(idx);
    const checkActiveTab = (idx,className) => activeIndex === idx ? className : "";

    return(
        <div className="w-[550px]">
            <div className="tab-menus h-[52px] border-b border-[#6F7976] p-[4px_4px_12px_4px] flex justify-between">
                {
                    TDL.map((data)=>(
                        <button key={data.id} className={`p-[6px_12px_6px_12px] text-base font-semibold ${checkActiveTab(data.id,'bg-[#99F3DE]')}`} onClick={()=>handleTabClick(data.id)}>{data.tite}</button>
                    ))
                }
            </div>
            <div className="tab-contents my-7">
                {
                    TDL.map((data)=>(
                        <div key={data.id} className={`tabContent ${checkActiveTab(data.id,'active')}`}>
                            {data.content}
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default DoctorOptionsTab;