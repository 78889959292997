import { Link } from "react-router-dom";

import "./home.css";

import doctorCardBGMD from "../asserts/home/doctor-card-section-md.png";
import doctorCardDoctor from "../asserts/home/doctor-card-section-doctor-with-bg.png";

function DoctorCardSection(){
    return(
        <>
        {/* small and medium screen */}
        <section className="2xl:hidden doctor-card h-fit px-2 py-6 bg-light-surface sm:px-0">
            <div className="doctor-image grid place-items-center bg-white sm:bg-[length:834px_508px]" style={{backgroundImage:`url(${doctorCardBGMD})`}}>
                <img src={doctorCardDoctor} alt="Doctor" className="w-[286px] h-[315.28px] sm:w-[410.4px] sm:h-[452.42px]" />
            </div>
            <div className="doctor-card p-3 bg-white rounded-3xl sm:p-[18px] sm:rounded-none">
                <div className="doc-card-content-text h-[316px] px-4 py-3 bg-[#FFFDF9] rounded-2xl border border-dashed border-[#84D6C2] flex flex-col gap-6 sm:w-[693px] sm:mx-auto sm:bg-[#D9D9D900] sm:rounded-[23px] sm:border-2">
                    <div className="doc-card-content flex flex-col gap-3 sm:gap-6 sm:text-center">
                        <h3 className="text-lg font-bold text-[#066B5B] sm:text-[28px] sm:leading-[36px]">
                            Aushadham is an amazing place for Doctors and other health professionals
                        </h3>
                        <p className="text-sm font-medium text-[#6F7976] sm:text-[22px] sm:leading-[28px]">
                            One stop solution for patient management, patient care, easy access and much more.
                        </p>
                        <h4 className="text-base font-bold text-[#001848] sm:text-lg">
                            Join us today to bring a positive and Healthier change
                        </h4>
                    </div>
                    <div className="button text-center">
                        <Link to="/doctor/home" className="bg-[#066B5B] rounded-[35px] px-[66px] py-3 text-lg text-white font-bold sm:px-20 sm:py-5 2xl:px-20 2xl:py-5">Join Aushadham</Link>
                    </div>
                </div>
            </div>
        </section>

        {/* large screens */}
        <section className="max-2xl:hidden doctor-card w-screen h-screen bg-light-surface flex items-center justify-center">
            <div className="w-full flex flex-row-reverse justify-around" style={{backgroundImage:`url(${doctorCardBGMD})`}}>
                <div className="doctor-image flex flex-col justify-center mr-10">
                    <img src={doctorCardDoctor} alt="Doctor" className="w-[286px] h-[315.28px] sm:w-[410.4px] sm:h-[452.42px]" />
                </div>
                <div className="doctor-card p-[18px] bg-white rounded-[30px] h-[411px] w-[693px] m-auto">
                    <div className="doc-card-content-text h-full bg-[#D9D9D900] rounded-[23px] border-2 border-dashed border-[#84D6C2] px-2 py-8 flex flex-col justify-around gap-6">
                        <div className="doc-card-content flex flex-col gap-6 text-center">
                            <h3 className="text-[28px] leading-[36px] font-bold text-[#066B5B]">
                                Aushadham is an amazing place for Doctors and other health professionals
                            </h3>
                            <p className="text-[22px] leading-[28px] font-medium text-[#6F7976]">
                                One stop solution for patient management, patient care, easy access and much more.
                            </p>
                            <h4 className="text-lg font-bold text-[#001848]">
                                Join us today to bring a positive and Healthier change
                            </h4>
                        </div>
                        <div className="button h-16 text-center">
                            <Link to="/doctor/home" className="bg-[#066B5B] rounded-[35px] text-lg text-white font-bold px-20 py-5">Join Aushadham</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}


export default DoctorCardSection;