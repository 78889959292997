import { useState, useEffect, useRef } from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

import { useAuth } from "../../../contexts/AuthContext";

import FileUploadModal from "../../components/modal/FileUploadModal";


const Confirmation = () => {
    const navigate = useNavigate();
    const {userData} = useAuth();

    const initialState = {
        present_health_issues: [],
        past_health_issues: [],
        atMedication: null,
        haveAllergies: null,
        allergyype: "",
        family_medical_history: [{ relation: '', history: '' }],
        lifestyle_habits: [],
        vaccination: ""
    };

    const [formData, setFormData] = useState(initialState);
    const formRef = useRef();    
    const [modalOpen, setModalOpen] = useState(false);
    const [uploadField, setUploadField] = useState("");
    const [fileNames, setFileNames] = useState({});
    const [selectedFiles, setSelectedFiles] = useState({});

    const [validationMessages, setValidationMessages] = useState({});

    const issues = ['Diabetes', 'Gastritis', 'High Blood pressure', 'Low Blood pressure', 'Add more'];
    const lifeStyle = ['Yoga', 'Exercises', 'Meditation', 'Smoking', 'Alcohol consumption'];

    const handleCheckboxChange = (category, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [category]: prevState[category].includes(value) 
                ? prevState[category].filter(item => item !== value) 
                : [...prevState[category], value]
        }));
    };

    const handleInputChange = (category, value) => {
        setFormData((prevState) => ({ ...prevState, [category]: value }));
    };

    const handleFamilyMedicalHistoryChange = (index, field, value) => {
        const newHistory = [...formData.family_medical_history];
        newHistory[index][field] = value;
        setFormData({ ...formData, family_medical_history: newHistory });
    };

    const addFamilyMedicalHistory = () => {
        setFormData({ 
            ...formData, 
            family_medical_history: [...formData.family_medical_history, { relation: '', history: '' }] 
        });
    };

    const deleteFamilyMedicalHistory = (index) => {
        const newFamilyMH = [...formData.family_medical_history];
        newFamilyMH.splice(index, 1);
        setFormData({ ...formData, family_medical_history: newFamilyMH });
    };

    const validateForm = () => {
        let isValid = true;
        let messages = {};

        if (!formData.present_health_issues.length) {
            messages.present_health_issues = "Please select at least one present health issue.";
            isValid = false;
        }

        if (!formData.past_health_issues.length) {
            messages.past_health_issues = "Please select at least one past health issue.";
            isValid = false;
        }

        if (formData.atMedication === null) {
            messages.atMedication = "Please specify if you are on medication.";
            isValid = false;
        }

        if (formData.haveAllergies === null) {
            messages.haveAllergies = "Please specify if you have any allergies.";
            isValid = false;
        } else if (formData.haveAllergies && !formData.allergyype) {
            messages.allergyype = "Please mention your type of allergies.";
            isValid = false;
        }

        if (!formData.vaccination) {
            messages.vaccination = "Please select a vaccination.";
            isValid = false;
        }

        if (!formData.lifestyle_habits.length) {
            messages.lifestyle_habits = "Please select at least one lifestyle habit.";
            isValid = false;
        }

        setValidationMessages(messages);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Proceed with form submission
            console.log("Form Submitted", formData);
            const formRefData = new FormData();
            formRefData.append("patient_id", userData.patient_id);

             // Append selected files
            for (let key in selectedFiles) {
                formRefData.append(key, selectedFiles[key]);
            }
        
            for (let key in formData) {
                if(typeof(formData[key]) === 'object'){
                    formRefData.append(key, JSON.stringify(formData[key]));
                } else {
                    formRefData.append(key, formData[key]);
                }
            }

            // debug console out
            for (let [key, value] of formRefData.entries()) {
                console.log(`${key}: ${value}`);
            }

            await axios.post("/api/patient/profile/registration-details", formRefData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res=>{
                console.log(res.data);
                navigate('/checkout')
            }).catch(err=>console.error(err))
        }
    };

    useEffect(()=>{
        if(!userData) return;

        axios.post("/api/patient/profile/get-registration-details", {patient_id: userData.patient_id})
        .then(res=>{
            console.log(res.data)
            if(res.data.status){
                setFormData({...res.data.details})
            }
            console.log(formData)
        })
        .catch(err=>console.error(err))
    },[])

    const handleFileSelect = (file) => {
        if (uploadField) {
          setSelectedFiles(prevSelectedFiles => ({ ...prevSelectedFiles, [uploadField]: file }));
          setFileNames(prevFileNames => ({ ...prevFileNames, [uploadField]: file.name }));
          console.log("File selected for field:", uploadField, "with file:", file.name); // Log for debugging
        }
    };

    return (
        <section className="w-screen min-h-screen bg-[#E5FFF6]">
            <div className="py-10 grid place-items-center place-content-center gap-5">
                <h1 className="font-medium text-4xl text-[32px]">Confirm your details before proceeding</h1>
                <div className="w-[1280px] h-[550px] pt-10 pb-[47px] bg-white rounded-2xl grid place-items-center overflow-auto">
                <form className="w-full px-4 md:px-20 lg:px-52" ref={formRef} onSubmit={handleSubmit}>
                    <div className="mb-8">
                        <label htmlFor="selectpresent" className="font-bold text-base text-[#061D2E]">
                            Select your present health issues<span className="text-base font-normal text-[#B3261E]">*</span>
                        </label>
                        <div className="mt-4 sm:grid flex flex-wrap gap-4 grid-flow-col auto-cols-max sm:gap-1 mb-4">
                            {issues.map(issue => (
                                <div key={issue} className="w-full">
                                    <input
                                        type="checkbox"
                                        id={`checkbox-present-${issue}`}
                                        className="hidden"
                                        checked={formData.present_health_issues.includes(issue)}
                                        onChange={() => handleCheckboxChange('present_health_issues', issue)}
                                    />
                                    <label
                                        htmlFor={`checkbox-present-${issue}`}
                                        className={`w-full text-center text-sm border border-black cursor-pointer transition duration-300 ease-in-out transform rounded-md p-2
                                        ${formData.present_health_issues.includes(issue) ? 'bg-[#DAE2FF] text-black' : 'bg-white'}`}
                                    >
                                        {issue}
                                    </label>
                                </div>
                            ))}
                        </div>
                        {validationMessages.present_health_issues && <p className="text-red-500">{validationMessages.present_health_issues}</p>}
                    </div>
                    <div className="mb-8">
                        <label htmlFor="selectpast" className="font-bold text-base text-[#061D2E]">
                            Select your past health issues<span className="text-base font-normal text-[#B3261E]">*</span>
                        </label>
                        <div className="mt-4 sm:grid flex flex-wrap gap-4 grid-flow-col auto-cols-max sm:gap-1 mb-4">
                            {issues.map(issue => (
                                <div key={issue} className="w-full">
                                    <input
                                        type="checkbox"
                                        id={`checkbox-past-${issue}`}
                                        className="hidden"
                                        checked={formData.past_health_issues.includes(issue)}
                                        onChange={() => handleCheckboxChange('past_health_issues', issue)}
                                    />
                                    <label
                                        htmlFor={`checkbox-past-${issue}`}
                                        className={`w-full text-center text-sm border border-black cursor-pointer transition duration-300 ease-in-out transform rounded-md p-2
                                        ${formData.past_health_issues.includes(issue) ? 'bg-[#DAE2FF] text-black' : 'bg-white'}`}
                                    >
                                        {issue}
                                    </label>
                                </div>
                            ))}
                        </div>
                        {validationMessages.past_health_issues && <p className="text-red-500">{validationMessages.past_health_issues}</p>}
                    </div>
                    <div className="text-base mb-8">
                        <h4 className="text-base font-bold">
                            At, present I am on the medication<span className="text-base font-normal text-[#B3261E]">*</span>
                        </h4>
                        <div className="flex gap-3">
                            <label className="flex px-3 py-2 my-3 cursor-pointer">
                                <input
                                    type="radio"
                                    name="atMedication"
                                    checked={formData.atMedication === true}
                                    onChange={() => handleInputChange('atMedication', true)}
                                />
                                <p className="pl-2">Yes</p>
                            </label>
                            <label className="flex px-3 py-2 my-3 cursor-pointer">
                                <input
                                    type="radio"
                                    name="atMedication"
                                    checked={formData.atMedication === false}
                                    onChange={() => handleInputChange('atMedication', false)}
                                />
                                <p className="pl-2">No</p>
                            </label>
                        </div>
                        {validationMessages.atMedication && <p className="text-red-500">{validationMessages.atMedication}</p>}
                    </div>
                    <div className="mb-8">
                        <h4 className="text-base font-bold">
                            Upload your present medical prescriptions (if you have any)<span className="text-base font-normal text-[#B3261E]">*</span>
                        </h4>
                        <label 
                        htmlFor="present_medical_prescriptions" 
                        className="lg:w-64 text-base font-medium text-[#061D2E] flex flex-col"
                        onClick={()=>{
                            setUploadField("present_medical_prescriptions");
                            setModalOpen(true);
                        }}
                        >
                            <p className="bg-[#DAE2FF] p-3 text-center mt-4 rounded-full">
                                Choose File
                            </p>
                        </label>
                        <p className="text-sm text-[#AEA9B1]">{fileNames["present_medical_prescriptions"] || ""}</p>
                    </div>
                    <div className="text-base mb-8">
                        <h4 className="text-base font-bold">
                            Do you have any allergies?<span className="text-base font-normal text-[#B3261E]">*</span>
                        </h4>
                        <div className="flex gap-3">
                            <label className="flex px-3 py-2 my-3 cursor-pointer">
                                <input
                                    type="radio"
                                    name="haveAllergies"
                                    checked={formData.haveAllergies === true}
                                    onChange={() => handleInputChange('haveAllergies', true)}
                                />
                                <p className="pl-2">Yes</p>
                            </label>
                            <label className="flex px-3 py-2 my-3 cursor-pointer">
                                <input
                                    type="radio"
                                    name="haveAllergies"
                                    checked={formData.haveAllergies === false}
                                    onChange={() => handleInputChange('haveAllergies', false)}
                                />
                                <p className="pl-2">No</p>
                            </label>
                        </div>
                        {validationMessages.haveAllergies && <p className="text-red-500">{validationMessages.haveAllergies}</p>}
                    </div>
                    {formData.haveAllergies && (
                        <div className="mb-8">
                            <label htmlFor="allergies-name" className="block text-base font-medium text-[#061D2E]">Mention your type of allergies <span className="text-base font-normal text-[#B3261E]">*</span></label>
                            <select
                                id="allergies-name"
                                name="allergies-name"
                                value={formData.allergyype}
                                onChange={(e) => handleInputChange('allergyype', e.target.value)}
                                required={formData.haveAllergies}
                                className="h-16 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01"
                            >
                                <option value="">Select</option>
                                <option value="celery">Celery</option>
                                <option value="egg">Egg</option>
                                <option value="fish">Fish</option>
                                <option value="milk">Milk</option>
                                <option value="peanuts">Peanuts</option>
                                <option value="sesame">Sesame</option>
                                <option value="soy">Soy</option>
                                <option value="dust">Dust</option>
                                <option value="pollen">Pollen</option>
                                <option value="medications">Medications</option>
                            </select>
                            {validationMessages.allergyype && <p className="text-red-500">{validationMessages.allergyype}</p>}
                        </div>
                    )}
                    <div className="mb-8 relative">
                        <label htmlFor="family-medical" className="text-base font-bold">Family Medical History<span className="text-base font-normal text-[#B3261E]">*</span></label>
                        {formData.family_medical_history.map((entry, index) => (
                            <div key={index} className="flex lg:flex-row flex-col items-center gap-2 mb-4">
                                <div>
                                    <select
                                        value={entry.relation}
                                        onChange={(e) => handleFamilyMedicalHistoryChange(index, 'relation', e.target.value)}
                                        className="h-16 lg:w-56 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full border-[0.4px] border-[#6F7976] rounded shadow-input-01"
                                    >
                                        <option value="">Select Relation</option>
                                        <option value="father">Father</option>
                                        <option value="mother">Mother</option>
                                        <option value="sister">Sister</option>
                                        <option value="brother">Brother</option>
                                        <option value="spouse">Spouse</option>
                                        <option value="son">Son</option>
                                        <option value="daughter">Daughter</option>
                                    </select>
                                </div>
                                <div>
                                    <select
                                        value={entry.history}
                                        onChange={(e) => handleFamilyMedicalHistoryChange(index, 'history', e.target.value)}
                                        className="h-16 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01"
                                    >
                                        <option value="">Select or enter texts to add history</option>
                                        <option value="diabetes">Diabetes</option>
                                        <option value="gastritis">Gastritis</option>
                                        <option value="high_blood_pressure">High Blood pressure</option>
                                        <option value="low_blood_pressure">Low Blood pressure</option>
                                        <option value="add_more">Add More</option>
                                    </select>
                                </div>                                
                                {index>0 && (<button
                                    type="button"
                                    onClick={() => deleteFamilyMedicalHistory(index)}
                                    className="ml-4 bg-red-500 text-white text-lg rounded-full h-8 w-8 mt-2 flex items-center justify-center"
                                >
                                    X
                                </button>)}
                            </div>
                        ))}
                        <div className="flex mt-2">
                            <button
                                type="button"
                                onClick={addFamilyMedicalHistory}
                                className="bg-[#B7FFEC] ml-4 text-4xl rounded-full h-16 w-16 flex items-center justify-center absolute bottom-4 right-[22%]"
                            >
                                +
                            </button>
                        </div>
                    </div>
                    <div className="mb-8">
                        <label htmlFor="selecthabit" className="font-bold text-base text-[#061D2E]">
                            Select your Lifestyle Habits<span className="text-base font-normal text-[#B3261E]">*</span>
                        </label>
                        <div className="mt-4 sm:grid flex flex-wrap gap-4 grid-flow-col auto-cols-max sm:gap-1 mb-4">
                            {lifeStyle.map(life => (
                                <div key={life} className="w-full">
                                    <input
                                        type="checkbox"
                                        id={`checkbox-habit-${life}`}
                                        className="hidden"
                                        checked={formData.lifestyle_habits.includes(life)}
                                        onChange={() => handleCheckboxChange('lifestyle_habits', life)}
                                    />
                                    <label
                                        htmlFor={`checkbox-habit-${life}`}
                                        className={`w-full text-center text-sm border border-black cursor-pointer transition duration-300 ease-in-out transform rounded-md p-2
                                        ${formData.lifestyle_habits.includes(life) ? 'bg-[#DAE2FF] text-black' : 'bg-white'}`}
                                    >
                                        {life}
                                    </label>
                                </div>
                            ))}
                        </div>
                        {validationMessages.lifestyle_habits && <p className="text-red-500">{validationMessages.lifestyle_habits}</p>}
                    </div>
                    <div className="mb-8">
                        <label htmlFor="vaccination" className="block text-base font-medium text-[#061D2E]">Did you take any vaccination? If Yes, please select one from below.<span className="text-base font-normal text-[#B3261E]">*</span></label>
                        <select
                            id="vaccination"
                            name="vaccination"
                            value={formData.vaccination}
                            onChange={(e) => handleInputChange('vaccination', e.target.value)}
                            required
                            className="h-16 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01"
                        >
                            <option value="">Select</option>
                            <option value="covid-19">COVID-19</option>
                            <option value="hepatitis_b">Hepatitis B</option>
                            <option value="hepatitis_a">Hepatitis A</option>
                            <option value="flu_vaccine">Flu Vaccine</option>
                            <option value="chickenpox">Chickenpox</option>
                            <option value="polio">Polio</option>
                            <option value="yellow_fever">Yellow Fever</option>
                            <option value="rabies">Rabies</option>
                        </select>
                        {validationMessages.vaccination && <p className="text-red-500">{validationMessages.vaccination}</p>}
                    </div>
                    <div className="flex justify-between mt-5">
                        <button type="button" className="px-8 py-4 border border-gray-400 text-sm font-medium font-roboto text-[#006B5B] rounded-full">
                            Edit
                        </button>
                        <button type="submit" className="px-8 py-4 bg-[#006B5B] text-sm font-medium font-roboto text-white rounded-full">
                            Submit
                        </button>
                    </div>
                </form>
                </div>
            </div>

            <FileUploadModal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              onFileSelect={handleFileSelect}
            />
        </section>
    );
};

export default Confirmation;