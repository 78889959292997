import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import { useAuth } from '../../../contexts/AuthContext';

function ProfileDetails() {
    const navigate = useNavigate();
    const [medicalHistory, setMedicalHistory] = useState(null);

    const handleRadioChange = (event) => {
        setMedicalHistory(event.target.value);
    };

    const confirmLink = medicalHistory === 'true' ? '/confirmation' : '/checkout';

    const handleConfirm = ()=>{
        const booking_details = JSON.parse(localStorage.getItem("booking_details"));
        if(booking_details){
            booking_details.previous_medical_issue = medicalHistory;
            localStorage.setItem("booking_details", JSON.stringify(booking_details));
        } else {
            console.error("booking details not found in localstorage")
        }

        navigate(confirmLink);
    }
    return (
        <section className="w-screen min-h-screen bg-[#E5FFF6]">
            <div className="py-10 grid place-items-center place-content-center gap-5">
                <h1 className="font-medium text-4xl text-[32px]">Confirm your details before proceeding</h1>
                <div className="w-[1280px] h-[550px] bg-white rounded-2xl grid place-content-center place-items-center gap-8 overflow-auto">
                    <PatientDetailsConfirmForm onRadioChange={handleRadioChange} />
                    <div className="w-[844px] flex justify-between">
                        <button className="w-[124px] h-11 rounded-[100px] border border-[#6F7976] text-light-primary text-sm font-medium font-roboto">Edit</button>
                        <button onClick={handleConfirm} className="w-[142px] h-11 rounded-[100px] bg-light-primary text-white text-sm font-medium font-roboto">Confirm</button>
                    </div>
                </div>
            </div>
        </section>
    );
}

function PatientDetailsConfirmForm({ onRadioChange }) {
    const {userData} = useAuth();
    const [patientDetails, setPatientDetails] = useState({});

    useEffect(()=>{
        if(!userData) return;
        axios.post("/api/patient/profile/get-personal-details", {patient_id: userData.patient_id})
        .then(res=>{
            if(res.data.status){
                setPatientDetails(res.data.details);
            }

            console.log(patientDetails)
        })
        .catch(err=>console.log(err))
    },[])

    return (
        <div className="w-[844px] flex justify-between">
            <div className="left w-[312px] flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                    <label htmlFor="fullName" className="text-base font-medium">Full Name</label>
                    <input type="text" id="fullName" value={patientDetails.full_name} className="text-sm font-normal text-[#131313] rounded border border-[#4973D5] px-5 py-[15.5px]" readOnly />
                </div>
                <div className="flex flex-col gap-3">
                    <label htmlFor="dob" className="text-base font-medium">Date of Birth</label>
                    <input type="date" id="dob" value={patientDetails.dob} className="text-sm font-normal text-[#131313] rounded border border-[#4973D5] px-5 py-[15.5px]" readOnly />
                </div>
                <div className="flex flex-col gap-3">
                    <label htmlFor="phone" className="text-base font-medium">Phone Number</label>
                    <input type="tel" id="phone" pattern="[0-9]{10}" value={patientDetails.mobile} className="text-sm font-normal text-[#131313] rounded border border-[#4973D5] px-5 py-[15.5px]" readOnly />
                </div>
            </div>
            <div className="right w-[312px] flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                    <label htmlFor="email" className="text-base font-medium">Email ID</label>
                    <input type="email" id="email" value={patientDetails.email} className="text-sm font-normal text-[#131313] rounded border border-[#4973D5] px-5 py-[15.5px]" readOnly />
                </div>
                <div className="flex flex-col gap-3">
                    <label htmlFor="gender" className="text-base font-medium">Gender</label>
                    <select id="gender" value={patientDetails.gender} className="text-sm font-normal text-[#131313] rounded border border-[#4973D5] px-5 py-[15.5px]">
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className="flex flex-col gap-3">
                    <p className="font-medium text-base roboto">Do you have any Medical History?</p>
                    <div className="flex flex-row gap-8">
                        <div className="flex items-center mb-4">
                            <input 
                                id="radio-yes" 
                                type="radio" 
                                value={true}
                                name="medicalHistory" 
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" 
                                onChange={onRadioChange} 
                            />
                            <label htmlFor="radio-yes" className="ms-2 text-sm font-medium text-gray-900">Yes</label>
                        </div>
                        <div className="flex items-center mb-4">
                            <input 
                                id="radio-no" 
                                type="radio" 
                                value={false}
                                name="medicalHistory" 
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" 
                                onChange={onRadioChange} 
                            />
                            <label htmlFor="radio-no" className="ms-2 text-sm font-medium text-gray-900">No</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileDetails;