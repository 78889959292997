import { useState } from "react";
import { NavLink } from "react-router-dom";

import { IoMdSearch } from "react-icons/io";
import { LuMenu } from "react-icons/lu";
import { IoIosClose } from "react-icons/io";

import NavLogo from "../../asserts/aushadamNavLogo.jpg"

import "./Navbar.css"

import DoctorSignIn from "../../../components/auth/doctor/SignIn";

import { useAuth } from "../../../../contexts/AuthContext";

function Navbar(){

    const {isAuthenticated,logout} = useAuth();

    const [isOpen, setIsOpen] = useState(false);
    const [showDocSignIn, setShowDocSignIn] = useState(false);
    
    return(
        <>
            <nav className="w-full h-[79.1px] flex items-center justify-between bg-light-surface lg:bg-white lg:w-screen lg:justify-around">
                {/* part 1/3 of nav */}
                <div className="navBrand">
                    <img src={NavLogo} alt="Aushadham" className="w-[86px] h-[54.17px] rounded-[148px] lg:rounded-none" />
                </div>

                {/* part 2/3 of nav */}
                <div className={`navItems w-full h-[336px] max-lg:absolute top-20 z-20 p-2 ${isOpen?'block':'hidden'} bg-light-surface lg:bg-white lg:h-auto lg:w-auto lg:flex lg:items-center lg:justify-between`}>
                    <ul className="text-poppins bg-light-surface text-[#171D1B] text-lg font-normal whitespace-nowrap flex flex-col gap-3 items-center max-lg:h-screen lg:bg-white lg:flex-row lg:gap-8">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/doctors">Doctors</NavLink></li>
                        <li>Hospitals</li>
                        <li>About Us</li>
                        <li className="text-Green max-lg:hidden"><IoMdSearch /></li>
                        <li className="lg:hidden text-Green" onClick={()=>setShowDocSignIn(!showDocSignIn)}>Sign in</li>
                    </ul>
                </div>


                {/* part 3/3 of nav */}
                <div className="navMenu text-3xl font-semibold lg:hidden">
                    <button onClick={()=>setIsOpen(!isOpen)}>
                        {isOpen?<IoIosClose />:<LuMenu />}
                    </button>
                </div>
                {
                    !isAuthenticated?(
                    <div className="navAuth hidden lg:w-[248px] lg:h-[44px] lg:flex gap-2">
                        <button onClick={()=>setShowDocSignIn(!showDocSignIn)} className="border-2 border-Green px-2 py-1 rounded-3xl lg:w-[97px] lg:px-[10px] lg:py-[20px] lg:flex lg:items-center lg:justify-center">Sign in</button>
                    </div>):(
                        <div className="navAuth hidden lg:w-[248px] lg:h-[44px] lg:flex gap-2">
                            <button className="border-2 border-Green px-2 py-1 rounded-3xl lg:w-[97px] lg:px-[10px] lg:py-[20px] lg:flex lg:items-center lg:justify-center" onClick={()=>{logout()}}>Logout</button>
                        </div>

                    )
                }

            </nav>
            {showDocSignIn ? <DoctorSignIn closeModal={() => setShowDocSignIn(false)} /> : null}
        </>
    );
}


export default Navbar;