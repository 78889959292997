
const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
};

function categorizeTimeSlots(timeSlots) {
    const categorizedAppointments = {
        morning: [],
        afternoon: [],
        evening: []
    };

    const getTimeInMinutes = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    // const convertTo12HourFormat = (time) => {
    //     const [hours, minutes] = time.split(':').map(Number);
    //     const period = hours >= 12 ? 'PM' : 'AM';
    //     const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    //     return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
    // };

    const morningEnd = getTimeInMinutes('12:00');
    const afternoonEnd = getTimeInMinutes('17:00');
    const eveningEnd = getTimeInMinutes('23:59');

    timeSlots.time_slots.forEach(slot => {
        slot.appointments.forEach(appointment => {
            const appointmentMinutes = getTimeInMinutes(appointment);

            if (appointmentMinutes < morningEnd) {
                categorizedAppointments.morning.push({label: convertTo12HourFormat(appointment), value: appointment, isAvail: true});
            } else if (appointmentMinutes < afternoonEnd) {
                categorizedAppointments.afternoon.push({label: convertTo12HourFormat(appointment), value: appointment, isAvail: true});
            } else if (appointmentMinutes <= eveningEnd) {
                categorizedAppointments.evening.push({label: convertTo12HourFormat(appointment), value: appointment, isAvail: true});
            }
        });
    });

    return categorizedAppointments;
}

function AddDateSelector(availDay, totalTS) {
    const date = new Date();
    let today = date.getDay();

    var days = ["SUN","MON","TUE","WED","THU","FRI","SAT"];
    var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let slotInfo = [];

    for (let i=0; i<7; i++){
        let newDate = new Date(date);
        newDate.setDate(date.getDate() + i);

        let idx = (i+today)%7;
        let tmp = {
            day: days[idx],
            hasSlot: availDay.includes(days[idx]),
            date: `${newDate.getDate()} ${month[newDate.getMonth()]} ${newDate.getFullYear()}`,
            availSlot: totalTS
        };
        slotInfo.push(tmp);
    }

    return slotInfo;
}

export {categorizeTimeSlots, AddDateSelector, convertTo12HourFormat};