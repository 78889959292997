import { useEffect, useState } from "react";
import axios from 'axios';

import { useAuth } from "../../../../../contexts/AuthContext";


const PersonalDetailsForm = ({step,nextStep}) => {

    const {userData} = useAuth();

    const [PDvalue, setPDvalue] = useState({full_name:"",mobile:"",email:"",gender:"",profession:""});


    useEffect(() => {
        if (!userData) return;
        axios.post("/api/doctor/registration/get-doctor-user", { _id: userData._id })
         .then(response => {
            setPDvalue({...response.data});
          })
         .catch(error => {
            console.error(error);
          });
      }, [userData]);
    const handlePDInput = (e)=>{
      setPDvalue({...PDvalue,[e.target.name]:e.target.value});
      console.log(PDvalue);
    }
    const handlePDSubmit = async (e) =>{
      e.preventDefault();
      console.log(PDvalue);
      const pd = await axios.post("/api/doctor/registration/personal-details",PDvalue)
      console.log(pd);
      await nextStep();
    }
  return (
        <>
            {step === 1 && (
            <form className='max-lg:mb-10 lg:mt-14 lg:mr-32' onSubmit={handlePDSubmit}>
        <div className="grid grid-flow-col gap-4 lg:justify-between lg:grid-rows-3 grid-rows-5 lg:gap-5">
            <div className="mb-4">
                <label htmlFor="fullname" className="block text-base font-medium text-[#061D2E]">Full Name <span className="text-base font-normal text-[#B3261E]">*</span></label>
                <input onChange={handlePDInput} type="text" id="full_name" name="full_name" value={PDvalue.full_name} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Enter your full name"/>
            </div>
            <div className="mb-4">
                <label htmlFor="mobile" className="block text-base font-medium text-[#061D2E]">Mobile Number <span className="text-base font-normal text-[#B3261E]">*</span></label>
                <input onChange={handlePDInput} type="tel" id="mobile" name="mobile" value={PDvalue.mobile} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Enter your mobile number"/>
            </div>
            <div className="mb-4">
                <label htmlFor="email" className="block text-base font-medium text-[#061D2E]">Email ID <span className="text-base font-normal text-[#B3261E]">*</span></label>
                <input onChange={handlePDInput} type="email" id="email" name="email" value={PDvalue.email} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="example@gmail.com"/>
            </div>
            <div className="mb-4">
                <label htmlFor="gender" className="block text-base font-medium text-[#061D2E]">Gender <span className="text-base font-normal text-[#B3261E]">*</span></label>
                <select onChange={handlePDInput} id="gender" name="gender" value={PDvalue.gender} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01">
                    <option value="">Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                </select>
            </div>
            <div className="mb-4">
                <label htmlFor="profession" className="block text-base font-medium text-[#061D2E]">Profession <span className="text-base font-normal text-[#B3261E]">*</span></label>
                <select onChange={handlePDInput} id="profession" name="profession" value={PDvalue.profession} required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[320px] border-[0.4px] border-[#6F7976] rounded shadow-input-01">
                    <option value="">Select</option>
                    <option value="doctor">Doctor</option>
                    <option value="dietitian">Dietitian</option>
                </select>
            </div>
        </div>
        <div className="flex justify-start lg:justify-end">
            <button type="submit" className="px-[70px] py-4 bg-blue-500 text-sm font-medium font-roboto text-white rounded-[100px]">
            Save & Continue
            </button>
        </div>
    </form>
  )}
    </>
  );
}

export default PersonalDetailsForm;
