import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {getADoc} from "../doctor/DoctorsList";

function DoctorCard() {
    const {dID} = useParams();
    const [doc, setDoc] = useState([]); 

    const getDoc = async ()=>{
        const d = await getADoc(dID);
        setDoc(d);
    }

    useEffect(()=>{
        getDoc();
    },[])

    return(
        <div className="h-72 flex flex-col items-center relative">
            <div className="bg-[#DAE2FF] w-[550px] h-[120px]"></div>

            <div className="doctor-card-info w-[451px] h-[168px] bg-[#F5FBF7] px-2 py-1 rounded-lg flex gap-6 absolute top-16">
                <img src={doc?.profile_photo?.includes('upload')?`http://127.0.0.1:1234/${doc?.profile_photo}`:`${doc?.profile_photo}`} alt={doc.full_name} className="w-40 h-40 rounded-lg object-cover" />
                <div className="doctor-details px-2 py-3 flex flex-col gap-2">
                    <h2 className="text-[22px] leading-7 font-medium">{doc.full_name}</h2>
                    <p className="text-sm font-medium">{doc.practice} {doc.title2}</p>
                    <p className="text-sm font-normal">Experience: {doc.experience || 0} years</p>
                    <div className="language pt-2 flex flex-wrap justify-start gap-2">
                        {doc?.languages?.map((language,Lidx)=>(
                            <p key={Lidx} className="bg-[#DAE2FF] rounded py-1 px-2 font-medium text-sm text-[#001848] font-roboto">{language.label}</p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default DoctorCard;