import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import { categorizeTimeSlots, AddDateSelector } from "./scheduleHelper";
import TimeSlots from "./TimeSlots";
import ModeOfAppointment from "./ModeOfAppointment";
import DaySlots from "./DaySlots";


function Schedule() {
    const {dID} = useParams();
    
    const [isLoading, setIsLoading] = useState(true);
    const [isDayLoaded, setIsdayLoaded] = useState(false);

    const [formData, setFormData] = useState({
        mode_of_appointment: 'offline',
        day: '',
        time: ''
    })
    const [timeSlots, setTimeSlots] = useState([]);
    const [daySlots, setDaySlots] = useState([]);
    const [clinicDetails, setClinicDetails] = useState({});

    const getTimeSlots = async ()=>{
        const ts = await axios.post("/api/doctor/personal/get-time-slot-details",{doctor_id: dID});
        if(ts.data.status){
            let cTS = categorizeTimeSlots(ts.data.details)
            setTimeSlots(cTS);
            let cDS = AddDateSelector(ts.data.details.days, ts.data.details.total_time_slots)
            setFormData(prev => ({ ...prev, day: cDS.find(d => d.hasSlot) }));
            setDaySlots(cDS);
        }
        console.log("ts.data.details : ",ts.data.details);
        console.log(timeSlots)
        console.log(daySlots)
        console.log(daySlots)
    }

    const getClinicDetails = async ()=>{
        const cd = await axios.post("/api/doctor/personal/get-clinic-details", {doctor_id: dID})
        if(cd.data.status){
            setClinicDetails(cd.data.details);
        }
    }
    

    useEffect(()=>{
        getTimeSlots();
        getClinicDetails();
        setIsLoading(false);
    },[dID])


    return(
        <div className="schedule w-[550px] h-[649px] flex flex-col gap-7 mt-12">
            <h2 className="text-xl font-semibold">Book an appointment</h2>
            <div className="appointment-fee px-5 py-4 bg-[#066B5B1C] rounded-lg shadow flex justify-between">
                <h4 className="text-base font-semibold">Appointment Fee</h4>
                <h4 className="text-base font-semibold">₹{clinicDetails.consultation_fee}</h4>
            </div>
            {!isLoading ? (<div className="appointment h-fit pb-2 shadow-md rounded-lg flex flex-col gap-4">
                {/* mode of appointment selector */}
                <ModeOfAppointment formData={formData} setFormData={setFormData} />

                {/* date selectors */}
                <DaySlots dID={dID} totalTS={timeSlots.total_time_slots} daySlots={daySlots} setDaySlots={setDaySlots} formData={formData} setFormData={setFormData} setIsdayLoaded={setIsdayLoaded} />

                <hr className="w-[534px] h-[0.6px] mx-auto bg-[#D0D0D0]" />

                {/* time selector */}
                {isDayLoaded && (<TimeSlots dID={dID} timeSlots={timeSlots} setTimeSlots={setTimeSlots} formData={formData} setFormData={setFormData} />)}

            </div>):(
                <div>
                    Loading...
                </div>
            )}
        </div>
    );
}

export default Schedule;