import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {getAllDoctor} from "./DoctorsList";

const Doctors=()=>{
    const [DoctorsList, setDoctorsList] = useState([]);
    const [isLoad, setIsLoad] = useState(true);

    const fetchDoc = async ()=>{
        try {
            const val = await getAllDoctor();
            setDoctorsList(val);
        } catch (error) {
            
        } finally {
            setIsLoad(false);
        }
    }

    useEffect(()=>{
        fetchDoc();
    },[])

    return(
        <>
        
        <div className="hidden w-screen bg-[#F5FBF7] lg:block lg:py-20">
            { !isLoad &&
                DoctorsList?.map((data,idx)=>(
                <div key={idx} className="bg-white grid grid-cols-5 rounded-xl h-[290px] w-[88.8889%] my-3 mx-auto">
                <div className="relative top-[24px] left-[16px]">
                    <img src={data.profile_photo.includes('upload')?`http://127.0.0.1:1234/${data.profile_photo}`:`${data.profile_photo}`} alt={data.full_name} className="w-[242px] h-[242px] object-cover rounded-lg"></img>
                </div>
                <div className="col-span-2 p-4">
                    <div className="flex justify-start mx-5 mt-5 mb-2 gap-3">
                        <p className="text-[22px] leading-7 font-medium whitespace-nowrap">{data.full_name}</p>
                        <p className="text-sm relative top-[11px] font-medium whitespace-nowrap">{data.title1 || data.practice}</p>
                        <p className="text-sm relative top-[11px] font-medium whitespace-nowrap">{data.title2}</p>
                    </div>
                    <div className="pl-5">
                        <p className="pt-1 font-[400] text-sm">Experience : <span>{data.experience} years</span></p>
                        <p className="pt-1 font-medium text-[16px]">{data.mode_of_appointment}</p>
                        <p className="pt-1 font-medium text-sm">Consultation Fee : <span className="text-[#228573]">₹ {data.consultation_fee || data.fee}</span></p>
                    </div>
                    <div className="domain pt-2 flex pl-5 justify-start gap-2">
                        {data.tags.map((expert,Eidx)=>(
                            <p key={Eidx} className="bg-[#FFF2A7] rounded py-1 px-2 font-medium text-sm">{expert.label || expert}</p>
                        ))}
                    </div>
                    <div className="language pt-2 flex pl-5 justify-start gap-2">
                        {data?.languages?.map((language,Lidx)=>(
                            <p key={Lidx} className="bg-[#DAE2FF] rounded py-1 px-2 font-medium text-sm">{language.label || language}</p>
                        ))}
                    </div>
                    <div className="pl-5 pt-3 flex justify-start gap-1 mt-2">
                        <div className="flex justify-start gap-1">
                            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#F4E47F"/>
                            </svg>
                            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#F4E47F"/>
                            </svg>
                            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#F4E47F"/>
                            </svg>
                            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#F4E47F"/>
                            </svg>
                            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#F4E47F"/>
                            </svg>
                        </div>
                        <p className="font-[400] text-[16px] ml-3"><span>{data.reviews}</span> reviews</p>
                    </div>
                </div>
                <div className="col-span-2">
                    <div>
                        <p className="mt-2 pr-2 flex justify-end gap-1 text-[16px] font-medium">Online<span>
                                <svg className="relative mt-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#43A08D"/>
                                </svg>
                            </span>
                        </p>
                        <p className="mt-2 pr-2 flex justify-end gap-1 text-[16px] font-medium">In-clinic<span>
                                <svg className="relative mt-1" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.33337 4.66665H8.66671V5.99998H7.33337V4.66665ZM7.33337 7.33331H8.66671V11.3333H7.33337V7.33331ZM8.00004 1.33331C4.32004 1.33331 1.33337 4.31998 1.33337 7.99998C1.33337 11.68 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.68 14.6667 7.99998C14.6667 4.31998 11.68 1.33331 8.00004 1.33331ZM8.00004 13.3333C5.06004 13.3333 2.66671 10.94 2.66671 7.99998C2.66671 5.05998 5.06004 2.66665 8.00004 2.66665C10.94 2.66665 13.3334 5.05998 13.3334 7.99998C13.3334 10.94 10.94 13.3333 8.00004 13.3333Z" fill="#BA1A1A"/>
                                </svg>
                            </span>
                        </p>
                    </div>
                    <div className="h-[234px]">
                        <button className="bg-[#228573] h-[60px] w-[336px] rounded-full text-white top-[155px] relative lg:-right-[15px] xl:left-[165px]"> 
                            <Link to={`/doctors/${data._id || idx}/bookAppointment`}>Book Appoinment</Link>
                        </button>
                    </div>
                </div>
                </div>
                ))
            }
        </div>
            <div className="grid gap-7 lg:hidden">
            {
                DoctorsList.map((data,idx)=>(
                   <div key={idx} className="w-11/12 mx-auto bg-white shadow-md rounded-2xl flex flex-col gap-5">
                        <div className="doctor-details p-2 flex flex-col gap-3">
                            <div className="flex gap-[6px]">
                                <img src={data.profile_photo.includes('upload')?`http://127.0.0.1:1234/${data.profile_photo}`:`${data.profile_photo}`} className="h-20 w-20" />
                                <div className="flex flex-col gap-2 px-2 py-3">
                                    <h2 className="text-lg font-medium">{data.full_name}</h2>
                                    <div className="text-xs font-medium flex gap-[2px]">
                                        <h4>{data.title1}</h4>
                                        <h4>{data.title2}</h4>
                                    </div>
                                    <div className="rating flex gap-1">
                                            <div className="flex gap-[2px]">
                                                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#F4E47F"/>
                                                </svg>
                                                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#F4E47F"/>
                                                </svg>
                                                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#F4E47F"/>
                                                </svg>
                                                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#F4E47F"/>
                                                </svg>
                                                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#F4E47F"/>
                                                </svg>
                                            </div>
                                            <p className="text-sm font-normal"><span>{data.reviews}</span> reviews</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="access-details flex flex-col gap-[6px]">
                                    <p className="text-sm font-normal text-black">Experience: {data.experience} Years</p>
                                    <p className="text-xs font-medium text-black">{data.mode_of_appointment}</p>
                                    <p className="text-sm font-medium text-black">Consultation Fee: <span className="text-light-primary">₹{data.consultation_fee || data.fee}</span></p>
                                </div>
                                <div className="flex gap-[6px] flex-wrap">
                                    {data.tags.map((expert,Eidx)=>(
                                        <p key={Eidx} className="bg-[#FFF2A7] rounded py-1 px-2 text-sm font-medium">{expert.label || expert}</p>
                                    ))}
                                </div>
                                <div className="flex gap-[6px]">
                                    {data?.languages?.map((language,Lidx)=>(
                                        <p key={Lidx} className="bg-[#DAE2FF] rounded py-1 px-2 font-medium text-sm">{language.label || language}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="booking-button flex flex-col gap-3">
                            <div className="flex justify-end gap-2">
                                <p className="flex justify-end items-center gap-1 text-sm font-medium">
                                    <span>
                                        Online
                                    </span>
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="6" r="6" fill="#43A08D"/>
                                        </svg>
                                    </span>
                                </p>
                                <p className="flex justify-end items-center gap-1 text-sm font-medium">
                                    <span>
                                        In-clinic
                                    </span>
                                    <span>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.33337 4.66665H8.66671V5.99998H7.33337V4.66665ZM7.33337 7.33331H8.66671V11.3333H7.33337V7.33331ZM8.00004 1.33331C4.32004 1.33331 1.33337 4.31998 1.33337 7.99998C1.33337 11.68 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.68 14.6667 7.99998C14.6667 4.31998 11.68 1.33331 8.00004 1.33331ZM8.00004 13.3333C5.06004 13.3333 2.66671 10.94 2.66671 7.99998C2.66671 5.05998 5.06004 2.66665 8.00004 2.66665C10.94 2.66665 13.3334 5.05998 13.3334 7.99998C13.3334 10.94 10.94 13.3333 8.00004 13.3333Z" fill="#BA1A1A"/>
                                        </svg>
                                    </span>
                                </p>
                            </div>
                            <button className="bg-[#228573] h-[50px] w-full mb-2 rounded-full text-white text-base font-medium"> 
                                <Link to={`/doctors/${idx}/bookAppointment`}>Book Appoinment</Link>
                            </button>
                        </div>
                   </div>
                ))
            }
            
            </div>
            </>
    )
}

export default Doctors;