import { NavLink } from "react-router-dom";

import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

import FootLogo from "../../asserts/AushadamFooterLogo2.png";

export default function Footer(){
    return(
        <footer className="w-screen px-[17px] py-6 relative bottom-0 bg-Dark text-white sm:p-[80px_113px_80px_113px] 2xl:p-[80px_100px_80px_100px]">
            <div className="FooterContainer flex flex-col gap-[59px] sm:items-center sm:gap-[60px]">
                <div className="TopOptions px-3 flex flex-col gap-8 sm:gap-[116px] 2xl:flex-row">
                    <div className="logo_copy basis-1/4 w-[301px] flex flex-col items-center gap-4 sm:gap-10">
                        <img src={FootLogo} alt="Aushadam" className="w-[121px] h-[75.81px] sm:w-[249px] sm:h-[156px]" />
                        <p className="text-base font-medium">&copy; 2023 Aushadham All rights reserved</p>
                    </div>
                    <div className="Options basis-3/4 flex flex-col justify-end gap-4 
                    sm:grid sm:grid-rows-2 sm:grid-cols-2 sm:gap-[18px] 
                    2xl:flex 2xl:flex-row 2xl:items-center 2xl:gap-10">
                        <div className="forDoctors flex flex-col gap-1">
                            <h5 className="text-sm font-semibold">For Doctors/Dietitians</h5>
                            <ul className="text-[#ffffffcc]">
                                <li>Features</li>
                                <li>Sign-up</li>
                                <li>Login</li>
                                <li>Request Call back</li>
                            </ul>
                        </div>
                        <div className="forClinics flex flex-col gap-1">
                            <h5 className="text-sm font-semibold">For Clinics</h5>
                            <ul className="text-[#ffffffcc]">
                                <li>Features</li>
                                <li>Sigin-up</li>
                                <li>Login</li>
                                <li>Request Call back</li>
                            </ul>
                        </div>
                        <div className="aboutAushadam flex flex-col gap-1">
                            <h5 className="text-sm font-semibold">Aushadham</h5>
                            <ul className="text-[#ffffffcc]">
                                <li>About us</li>
                                <li>Contact us</li>
                                <li>Locaion</li>
                                <li>Terms and Conditions</li>
                                <li>Privacy Policy</li>
                            </ul>

                        </div>
                        <div className="auth w-[152.77px] flex flex-col gap-4 text-center">
                            <NavLink to="/signup" className="text-[15px] leading-[18.29px] font-medium text-Green border-2 border-Green w-full py-3 rounded-3xl">Register</NavLink>
                            <NavLink to='/signin' className="text-[15px] leading-[18.29px] font-medium text-white bg-Green w-full  py-3 rounded-3xl">Log in</NavLink>
                        </div>
                    </div>
                </div>
                <div className="Socials flex justify-between items-center gap-[26px] sm:justify-center">
                    <p className="text-base font-normal whitespace-nowrap">Follow us:</p>
                    <p className="flex items-center gap-4 text-Green text-[38px]">
                        <FaFacebook />
                        <FaInstagram />
                        <FaTwitter />
                        <FaLinkedin />
                    </p>
                </div>
            </div>
        </footer>
    );
}