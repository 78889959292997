
import "./FAQ.css"

const faqList = [
    {
        question: "What is online consultation?",
        answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente, fugit?"
    },
    {
        question: "How can I do an online consultation?",
        answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente, fugit?"
    },
    {
        question: "Is online counselling expensive?",
        answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente, fugit?"
    },
    {
        question: "Is my online medical consultation secure?",
        answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente, fugit?"
    },
    {
        question: "Can I consult same doctor everytime?",
        answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente, fugit?"
    },
    {
        question: "How can I choose a specific doctor to consult?",
        answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente, fugit?"
    },
    {
        question: "Can I get a prescription after online medical consultation?",
        answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente, fugit?"
    },
]

function FAQ(){
   return (
        <section className="faq w-full bg-light-surface p-[24px_7px_24px_7px]">
            <div className="mx-auto flex flex-col items-center gap-[39px] xl:gap-8 2xl:w-[1000px]">
                <h1 className="text-[#1D2366] text-[54px] font-bold">FAQ's</h1>
                <div className="w-full text-base xl:text-2xl text-[#061D2E] font-normal flex flex-col gap-4">
                {
                    faqList.map((data,idx)=>(
                        <details key={idx} className="bg-white px-8 py-4">
                            <summary>{data.question}</summary>
                            <p>{data.answer}</p>
                        </details>
                    ))
                }
                </div>
            </div>
        </section>
    )
}

export default FAQ;