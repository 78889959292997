import { useState } from "react";
import PersonalDetailsForm from "./PersonalDetailsForm";
import RegistrationDetails from "./RegistrationDetails";

const PatientStepperForm = () => {
    const [step, setStep] = useState(1);
  
    const nextStep = () => {
      setStep(step + 1);
    };
  
    const prevStep = () => {
        setStep(step - 1);
      };

    const stepDetails = [
      "Personal Details" ,
      "Registration Details",
    ]

    return(
        <>
            <div className="p-5 lg:p-0">
                <p className="lg:text-4xl text-xl lg:font-normal font-bold">Lets Get Started</p>
                <p className="lg:text-base text-sm">Add your details now so that, it’s quicker to book your appointment later</p>
            </div>
            <div className="bg-white rounded-xl p-[32px] lg:my-5">
                <div className={`lg:${step===3?'hidden':''} grid grid-cols-2 lg:mx-80 justify-center`}>
                    {stepDetails.map((item,idx) => (
                    <div key={idx} className={`grid gap-6 h-16 rounded`}>
                        <div className="flex flex-row gap-5 justify-center px-1 text-[18px] font-[500] ">
                            <p className={`${step === 2 && idx+1 === 1 ? "bg-[#006B5B]":"bg-[#EEF0FF]" } rounded-full w-[60px] h-[60px] text-center text-3xl text-black p-3 `}>
                            {idx+1}
                            </p>
                            <p className="py-4 max-lg:text-base">{item}</p>
                        </div>
                    </div>
                    ))}
                </div>
                <div className={`lg:mx-80 h-5 bg-[#D7F3DF] text-sm ${step===3?'hidden':'flex'} justify-start rounded-full gap-2 lg:gap-16 flex-row ${step === 1 || step === 3 ? "md:gap-80" : ""}`}>
                {step === 2 ? (
                    <>
                    <div className="lg:w-3/6 w-48 bg-[#41B062] h-5 rounded-full text-center">
                    </div>
                    <div>
                        <p className=''>50% profile complete</p>
                    </div>
                    </>
                ) : (
                    <>
                    <div className="lg:w-20 w-6 bg-[#41B062] h-5 rounded-full"></div>
                    <div>
                        <p>10% Profile Complete</p>
                    </div>
                    </>
                )}
                </div>
                <div>    
                    <div className="mt-4">
                        <PersonalDetailsForm step={step} nextStep={nextStep} />
                        <RegistrationDetails step={step} prevStep={prevStep}/>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PatientStepperForm;