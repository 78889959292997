import Docter from "../asserts/home/expressive-young-woman-posing-studio 2.png";

// const CallBackSection = () => {
//   return (
//     <>
//       <section className="w-screen px-0 py-16 bg-[#F3FFFA]">
//         <div className="">
//           <div className="mx-24 mt-11 flex gap-10 z-0">
//             <div className="basis-1 sm:basis-2/3">
//               <p className="text-[#004135] leading-relaxed text-[54px] font-[700] mt-16">
//                 Still haven’t{" "}
//                 <span className="text-[#228674]">Decided yet?</span>
//               </p>
//               <p className="text-[#2F5D7F] leading-relaxed text-4xl">
//                 We are here to help you...
//               </p>
//               <p className="text-2xl leading-relaxed text-[#605D64]">
//                 Just tell us your time and we will call you back <br></br> to
//                 clarify your doubts.
//               </p>
//             </div>
//             <div className="basis-1 sm:basis-1/3">
//               <img
//                 src={Docter}
//                 className="scale-125"
//                 alt="callback section docter"
//               />
//             </div>
//           </div>
//           <div className="bg-white mx-24 z-20 relative p-10">
//             <p className="text-[#1D2366] text-[29px]">Choose your time slot:</p>
//             <div className="pt-5">
//               <form>
//                 <div className="grid grid-cols-3 gap-10">
//                   <div className="flex flex-col gap-2">
//                     <label htmlFor="fullName" className="font-bold">
//                       Full Name:
//                     </label>
//                     <input
//                       id="fullName"
//                       type="text"
//                       placeholder="Enter your full name"
//                       className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//                     />
//                   </div>
//                   <div className="flex flex-col gap-2">
//                     <label htmlFor="mobileNo" className="font-bold">
//                       Mobile No:
//                     </label>
//                     <input
//                       id="mobileNo"
//                       type="tel"
//                       placeholder="Enter your mobile number"
//                       className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//                     />
//                   </div>
//                   <div className="flex flex-col gap-2">
//                     <label htmlFor="timeSlot" className="font-bold">
//                       Time:
//                     </label>
//                     <select
//                       id="timeSlot"
//                       className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//                     >
//                       <option value="">Select a time slot</option>
//                       <option value="9am">9:00 AM</option>
//                       <option value="10am">10:00 AM</option>
//                       <option value="11am">11:00 AM</option>
//                     </select>
//                   </div>
//                   <div className="col-span-3 flex justify-center mt-6">
//                     <button className="bg-[#228573] text-sm text-white py-3 px-28 rounded items-center">
//                       Request a call back
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };


function CallBackSection() {
  return(
    <section className="callback-section p-[20px_7px_20px_7px] bg-[#F3FFFA]">
      <div className="callback-container 2xl:w-[1166px] 2xl:mx-auto">
        <CallbackBanner />
        <CallbackCard />
      </div>
    </section>
  );
}

function CallbackBanner() {
  return(
    <div className="banner-callback flex flex-col gap-1 2xl:flex-row 2xl:gap-8">
      <div className="contact w-[307px] mx-auto flex flex-col gap-2 2xl:justify-center 2xl:gap-5 2xl:w-[667px] 2xl:mx-0">
        <h2 className="text-4xl font-bold text-[#004135] 2xl:text-[54px] 2xl:leading-[72px]">
          Still haven't <span className="text-[#228674]">Decided yet?</span>
        </h2>
        <h3 className="text-base font-bold text-[#2F5D7F] 2xl:text-[32px] 2xl:leading-[40px] 2xl:font-medium">
          We are here to help you...
        </h3>
        <p className="text-xs font-normal text-[#605D64] 2xl:text-[22px] 2xl:leading-[28px]">
          Just tell us your time and we will call you back to clarify your doubts.
        </p>
      </div>
      <img src={Docter} alt="Call back doctor" className="w-[320px] h-[252.52px] 2xl:w-[460px] 2xl:h-[363px]" />
    </div>
  );
}

function CallbackCard() {
  return(
    <div className="callback-card px-[2px] py-[52px] flex flex-col gap-10 bg-white  relative -top-4
    2xl:px-[62.5px] 2xl:items-center 2xl:-top-5">
      <div className="input-detail-container flex flex-col gap-8 2xl:w-full">
        <h3 className="text-[29px] leading-[28px] font-medium text-[#1D2366]">Choose your time slot:</h3>
        <form action="" className="flex flex-col gap-[13px] 2xl:flex-row">
          <div className="flex flex-col gap-2 2xl:flex-1">
            <label htmlFor="" className='test-base font-semibold text-[#061D2E]'>Full Name</label>
            <input type="text" className="p-[15.5px_20px_15.5px_12px] rounded border-[0.4px]" placeholder="Full Name" />
          </div>
          <div className="flex flex-col gap-2 2xl:flex-1">
            <label htmlFor="" className='test-base font-semibold text-[#061D2E]'>Mobile Number</label>
            <input type="text" className="p-[15.5px_20px_15.5px_12px] rounded border-[0.4px]" placeholder="Mobile Number" />
          </div>
          <div className="flex flex-col gap-2 2xl:flex-1">
            <label htmlFor="" className='test-base font-semibold text-[#061D2E]'>Time</label>
            <input type="text" className="p-[15.5px_20px_15.5px_12px] rounded border-[0.4px]" placeholder="Time" />
          </div>
        </form>
      </div>
      <button className="bg-[#228573] px-12 py-4 rounded text-xl font-semibold text-white 2xl:w-[283px]">
        Request a call back
      </button>
    </div>
  );
}

export default CallBackSection;
