
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

import doctorConsult from "../asserts/home/doctor-consultation-image-container.png"
import personilizedPlan from "../asserts/home/personalised-plans-image-container.png"
import labAppointment from "../asserts/home/lab-appointments-image-container.png"
import healthRecord from "../asserts/home/healthrecords-image-container.png"


const TopServiceList = [
    {
        title: "Doctor Consultation",
        img: doctorConsult,
        description: "Book doctor Appointments online & offline with ease",
        footer: "Consult Now",
        link: "#"
    },
    {
        title: "Personalized Plans",
        img: personilizedPlan,
        description: "Get personalized health plans with consultation",
        footer: "Learn MORE",
        link: "#"
    },
    {
        title: "Lab Appointments",
        img: labAppointment,
        description: "Book convenient lab appointments for you ",
        footer: "COMING SOON",
        link: "#"
    },
    {
        title: "Health Records",
        img: healthRecord,
        description: "Keep track of your medical records effortlessly",
        footer: "LEARN MORE",
        link: "#"
    },
];

function OurTopServices() {
    return (
        <section className="w-screen px-0 py-16 sm:py-16">
            <div className="service-container relative px-0 py-0 grid grid-cols-1 gap-4 sm:gap-16 2xl:px-12 2xl:gap-16">
                <h1 className="text-2xl px-4 font-bold text-start text-[#004135] sm:text-[54px] sm:leading-[72px] sm:text-center">
                    Our <span className="text-[#228573]">Top Services</span>
                </h1>
                <div className="w-screen h-[328px] pl-4 sm:w-screen sm:px-8 lxl:w-[1244px] lxl:mx-auto 2xl:px-0 2xl:w-fit">
                    <div className="absolute z-[-1] left-0 translate-y-2/4 h-[138px] w-screen bg-[#FFFBF2]"></div>
                    
                    <ServiceCard service={TopServiceList} />
                </div>
            </div>
        </section>
    );
}

function ServiceCard({ service }) {
   
    return (
        <div className="h-[110%]">
            <Swiper
                className="our-top-services-swiper w-[320px] h-full sm:w-full 2xl:w-[1244px]"
                spaceBetween={20}
                slidesPerView={1.2}
                pagination={{clickable: true}}
                modules={[Pagination]}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                    1280: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                    1366: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                    1440: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                }}
            >
                {service.map((data, idx) => (
                    <SwiperSlide key={idx} className='w-[272px_!important]'>
                        <div className="w-[272px] h-[320px] py-8 px-4 rounded-[20px] bg-white border-t-[6px] border-t-[#6fb7aa] flex flex-col gap-5 shadow-service-card sm:h-[320px]">
                            <img className="h-[100px] w-[100px]" src={data.img} alt={data.title} />
                            <div className="content w-[240px] flex flex-col gap-3">
                                <h3 className="text-2xl font-medium text-[#0C3D5B]">{data.title}</h3>
                                <p className="text-[18px] leading-6 font-medium text-[#868686]">{data.description}</p>
                            </div>
                            <a className="text-base text-[#0040A1] uppercase font-bold" href={data.link}>{data.footer}</a>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default OurTopServices;