import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../../contexts/AuthContext";

import SignIn from "../../../components/auth/patient/SignIn";


const withAuth = (WrappedComponent) => {
    return (props) => {
        const navigate = useNavigate();
        const {isAuthenticated, isLoading} = useAuth();
        const [showSignIn, setShowSignIn] = useState(false);

        useEffect(()=>{
            if(!isLoading){
                if(!isAuthenticated){
                    setShowSignIn(true);
                }
            }
        },[isAuthenticated, isLoading]);

        const closeModal = () => {
            setShowSignIn(false);
            if(!isAuthenticated) navigate('/')
        }

        if(isLoading) return <div>Loading...</div>

        return(
            <>
                {showSignIn ? (
                    <SignIn closeModal={closeModal} />
                ):(
                    <WrappedComponent {...props} />
                )}
            </>
        )
    }
}

export default withAuth;