
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

import Patient from '../asserts/home/Image.png'
import Star from '../asserts/home/Star.png'


function PatientTestimonal(){

    return(
        <div>
            <section className="bg-[#EDF8F4] w-screen py-4 grid gap-[52px] max-sm:place-content-center max-sm:place-items-center
            sm:p-[60px_29px_60px_29px] sm:gap-16
            2xl:p-[60px_106px_60px_106px] 2xl:gap-16">
                <PatientTestimonalSlider />
                <ContactCTA />
            </section>
        </div>
    )
}


function PatientTestimonalSlider(){
    
    const paitentTestimonalList = [
        {
            name: "Sophie Moore",
            img: Patient,
            review: Star,
            title: "“One of a kind service”",
            desc: "Ultrices eros in cursus turpis massa tincidunt sem nulla pharetra diam sit amet nisl suscipit adipis.",
        },
        {
            name: "Sophie Moore",
            img: Patient,
            review: Star,
            title: "“One of a kind service”",
            desc: "Ultrices eros in cursus turpis massa tincidunt sem nulla pharetra diam sit amet nisl suscipit adipis.",
        },
        {
            name: "Sophie Moore",
            img: Patient,
            review: Star,
            title: "“One of a kind service”",
            desc: "Ultrices eros in cursus turpis massa tincidunt sem nulla pharetra diam sit amet nisl suscipit adipis.",
        },
    ];

    return(
        <div className="Patient-testimonial grid gap-4 lmd:w-[776px] sm:gap-10">
            <h1 className="text-start text-[28px] px-4 leading-[33.89px] text-[#004135] font-medium 
            sm:text-center sm:text-[40px] sm:leading-[52px] sm:font-bold
            2xl:text-center 2xl:text-[54px] 2xl:leading-[72px]">
                Patient <span className="text-[#228573]">testimonials</span>
            </h1>

            <div className="w-[360px] py-1 mx-auto px-4
            md:w-[710px]
            lmd:w-[776px]
            lg:w-[988px]
            xl:w-[1240px]
            lxl:w-[1330px]
            ">
                 <PatientTestimonalSliderCards PatientTestimonal={paitentTestimonalList} />
             </div>
        </div>
    );
}

function PatientTestimonalSliderCards(props) {

    return (
        <div className="">
            <Swiper
            className="h-[289px] 2xl:h-[296px]"
            spaceBetween={20}
            slidesPerView={1}
            centeredSlides={true}
            pagination={{clickable: true}}
            modules={[Pagination]}
            breakpoints={{
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1280: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1366: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    centeredSlides: false
                }
            }}
            >
                {
                    props.PatientTestimonal.map((data,idx)=>(
                        <SwiperSlide key={idx}>
                            <div className="w-[328px] h-[248px] bg-white rounded-3xl border-[#D4D2E3] border 
                            lg:w-[400px_!important]
                            2xl:shadow-patient-testimonial p-[16px_6px_32px_14px] grid gap-4 2xl:h-[296px] 2xl:w-[390px] 2xl:p-6">
                                <div className="flex gap-[19px]">
                                    <img src={data.img} alt={data.name} className='w-20 h-20' />
                                    <div className='grid gap-[14px]'>
                                        <p className="text-xl text-[#007E85] font-bold inter 2xl:pt-[15px] 2xl:pl-[22px]">{data.name}</p>
                                        <img src={data.review} alt="review stars" />
                                    </div>
                                </div>
                                <div className='grid gap-2'>
                                    <h1 className="text-lg font-medium text-[#333333] 2xl:text-[22px] 2xl:font-bold">{data.title}</h1>
                                    <p className="text-base text-[#555555] font-normal 2xl:text-lg">{data.desc}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
}


function ContactCTA(){
    return(
        <div className="bg-gradient-cta w-[324px] p-[25px_6px_16px_15px] grid grid-cols-1 gap-[26px] shadow-contact-cta 
        sm:w-[710px] sm:p-[42px_62px_42px_62px] sm:gap-5 sm:place-items-center
        lmd:w-[771.6px]
        xl:w-[1100px] xl:mx-auto
        2xl:w-[1226.6px] 2xl:py-[42px] 2xl:px-[84px] rounded-xl 2xl:flex 2xl:items-center 2xl:gap-[96px]">
            <div className="grid gap-[11px] sm:text-center 2xl:text-start 2xl:w-[647.6px]">
                <h1 className="text-[#3F4946] text-lg font-bold sm:text-4xl 2xl:text-4xl">Ready to get started ?</h1>
                <p className="text-[#616161] text-base font-normal sm:text-2xl sm:font-normal 2xl:text-2xl">consult our experts with prices starting <span className="text-[#228573] text-lg font-medium sm:font-bold 2xl:text-2xl 2xl:font-bold">@ just Rs.350</span></p>
            </div>
            <button className="w-[280px] text-center text-[#228573] text-base font-semibold bg-white border border-[#228573] py-3 px-0
            sm:w-[315px] sm:text-xl sm:font-bold
             2xl:h-[71px] 2xl:w-[315px] 2xl:py-5 rounded-full 2xl:text-xl 2xl:font-bold">
                Make an Appointment 
            </button>
        </div>
    );
}


export default PatientTestimonal