import axios from "axios";

import himadriChaudary from "../asserts/doctors/himadriChaudary.png"
import aswathi from "../asserts/doctors/aswathi.png"
import pushpanjali from "../asserts/doctors/pushpanjali.png"

const DoctorsList = [
    {
        _id: '1',
        full_name: "Dr. Himadri Chaudary",
        profile_photo: himadriChaudary,
        title1: "Ayurveda Practitioner",
        title2: "(BAMS, MD)",
        experience: "10 years",
        mode_of_appointment: "Available for Online appointment",
        fee: "400",
        tags: ["Yoga Expert","Thyroid","more"],
        language: ["Malayalam","Hindi","English"],
        reviews: '45',
    },
    {
        _id: '2',
        full_name: "Dr. Aswathi A. L. ",
        profile_photo: aswathi,
        title1: "Ayurveda Practitioner",
        title2: "(BAMS, MD)",
        experience: "10 years",
        mode_of_appointment: "Toronto, Canada",
        fee: "400",
        tags: ["Yoga Expert","Thyroid","more"],
        language: ["Malayalam","Hindi","English"],
        reviews: '45',
    },
    {
        _id: '3',
        full_name: "Dr. Pushpanjali",
        profile_photo: pushpanjali,
        title1: "Ayurveda Practitioner",
        title2: "(BAMS, MD)",
        experience: "10 years",
        mode_of_appointment: "Vizianagaram",
        fee: "400",
        tags: ["Yoga Expert","Thyroid","more"],
        language: ["Malayalam","Hindi","English"],
        reviews: '45',
    },
];


async function getAllDoctor() {
    try {
        const doc = await axios.get("/api/details/getAllDoctors");
        const data = doc.data;
        let a =[...DoctorsList, ...data];
        return a;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getADoc(id){
    try{
        const DocList = await getAllDoctor();
        const Doc = DocList.find(doc=>doc._id == id) || DocList[parseInt(id)];
        return Doc;
    } catch(err){
        console.log(err);
    }
}


export default DoctorsList;
export {getAllDoctor, getADoc};