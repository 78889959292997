
import Patient_Management from '../asserts/home/Patient_Management.png'
import Easy_Registration from '../asserts/home/Easy_Registration.png'
import Convienent_Time from '../asserts/home/Convienent_time_slots.png'

const TopServiceList = [
    {
        title: "Doctor Consultation",
        img: Patient_Management,
        description: "With our advanced management system—organize appointments and maintain detailed records for personalized, efficient service.",  
    },
    {
        title: "Personalized Plans",
        img: Easy_Registration,
        description: "Join our network swiftly. A few steps and you're part of a dynamic community dedicated to advancing healthcare.",
    },
    {
        title: "Lab Appointments",
        img: Convienent_Time,
        description: "Set and adjust convenient time slots, ensuring flexibility to focus more on delivering top-notch healthcare."
    },
    
];


function WhyJoinUs() {
    return(
        <section className="w-full p-[20px_11px_20px_11px] xl:py-16 bg-light-surface flex flex-col gap-12 2xl:px-32 2xl:py-12 2xl:gap-[52px] 2xl:items-center">
                <h1 className="text-4xl xl:text-[54px] font-bold text-center text-[#004135]">Why <span className="text-[#228573]">Join Us?</span> </h1>
                <div className="flex flex-col gap-8 xl:flex-row xl:gap-6 2xl:gap-[70px]">
                {
                    TopServiceList.map((data,idx)=>(
                        <ServiceCard service={data} key={idx} />
                    ))
                }
                </div>
            <button className='w-[322px] px-16 py-4 text-[20px] leading-[24.2px] font-semibold text-white rounded-[40px] bg-[#228674]'>
                Join Aushadam
            </button>
        </section>
    );
}

function ServiceCard(props) {
    return (
        <div className="mx-auto w-[332px] xl:w-[348px] h-[430px] px-8 py-4 rounded-[20px] bg-white border-t-[6px] border-t-[#6fb7aa] flex flex-col gap-5 shadow-md">
            <img className="h-[198px] w-[191px] mx-auto" src={props.service.img} alt={props.service.title} />
            <div className="w-[240px] flex flex-col gap-4 xl:gap-3">
                <h3 className="text-2xl font-bold xl:font-medium text-[#0C3D5B] text-center 2xl:font-bold">{props.service.title}</h3>
                <p className="text-lg font-medium text-[#868686] w-[300px] h-[96px]">{props.service.description}</p>
            </div>
        </div>
    );
}


export default WhyJoinUs;