import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

import { useAuth } from "../../../../../contexts/AuthContext";

import { WiTime4 } from "react-icons/wi";

const WorkDetails = ()=>{
    const navigate = useNavigate();
    const {userData} = useAuth();

    useEffect(()=>{
        if(!userData) return
        axios.get("/api/doctor/registration/check-valid-doctor-status/" + userData._id)
        .then(res => {
            console.log(userData._id)
            if(res.data.status) navigate("/doctor/dashboard/profile/personal-details")
        })
        .catch(error => {
            console.log(error);
        });
    },[])

    return(
        <div className="workDetails flex flex-col gap-[39px]">
            <div className="flex flex-col gap-6">
                <h2 className="text-[32px] leading-10 font-medium text-[#171D1B]">
                    Thank you for submitting your medical practice details!
                </h2>

                <p className="text-sm font-normal text-[#3F4946]">
                    We will now verify the information and grant you access shortly. 
                    Additionally, we would like to schedule a meeting to demonstrate 
                    how you can effectively utilize our platform.
                </p>
            </div>

            <div className="book-demo-card px-0 py-8 rounded-xl flex flex-col justify-center items-center gap-6 bg-[#DAE2FF] mb-10">
                <span className="text-[80px] text-[#658DF1]"><WiTime4 /></span>
                <h4 className="text-lg leading-[24px] font-medium text-center text-black">Please select a convenient time slot below to schedule the meeting:</h4>
                <button className="w-[234px] h-[44px] text-white rounded-[100px] bg-[#006B5B]">Book Demo</button>
            </div>
        </div>
    );
}


export default WorkDetails;