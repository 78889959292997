import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext"
import handleErrorAlert from "../../utils/handleErrorAlert";


function usePatientSignUp() {
    const navigate = useNavigate();

    const { login } = useAuth();
    const [loading, setLoading] = useState(null);

    async function signup(formData) {
        setLoading(true);
      
        try {
          const response = await axios.post("/api/patient/auth/signup", formData);
          console.log(response);
          if (response.status === 201) {
            login(response.data.token, response.data.user);
            navigate("/patient/profile-details");
          }
        } catch (error) {
          handleErrorAlert(error);
        } finally {
          setLoading(false);
        }
      }

    return {loading, signup}
}

export default usePatientSignUp;