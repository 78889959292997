import {useState, useEffect, createContext, useContext} from 'react'

const AuthContext = createContext();

export function AuthProvider({children}) {
    const [token, setToken] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const storedData = JSON.parse(localStorage.getItem('user_data'));

    useEffect(()=>{
        if(storedData){
            const {userToken, user} = storedData;
            setToken(userToken);
            setUserData(user);
            setIsAuthenticated(true);
        }
        setIsLoading(false);
    },[]);

    const login = (newToken, newData)=>{
        localStorage.setItem("user_data",JSON.stringify({userToken:newToken, user:newData}));
        setToken(newToken);
        setUserData(newData);
        setIsAuthenticated(true);
    }


    const logout = ()=>{
        localStorage.removeItem("user_data");
        setToken(null);
        setUserData(null);
        setIsAuthenticated(false);
    }

    return(
        <AuthContext.Provider value={{ token, isAuthenticated, isLoading, login, logout, userData }}>
            {children}
        </AuthContext.Provider>
    );
    
}

export const useAuth = ()=> useContext(AuthContext);