import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../../contexts/AuthContext";
import usePatientSignIn from "../../../../hooks/patient/usePatientSignIn";

import DotSpinner from "../../utils/Spinner";

function SignIn({ closeModal, setShowSignUp, setShowDocSignIn }) {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [patSignInError, setPatSignInError] = useState("");

    useEffect(() => {
        if (isAuthenticated) {
            closeModal();
            // navigate('/');
        }
    }, [isAuthenticated, closeModal, navigate]);

    const {loading, signin } = usePatientSignIn();

    const [inpData, setInpData] = useState({
        mobile: "",
        password: ""
    });

    function handleInput(e) {
        setInpData({
            ...inpData, [e.target.name]: e.target.value
        });
    }

    const handleSignIn = (e) => {
        e.preventDefault();
        signin(inpData, setPatSignInError);
    }

    const handleDocSignInClick = () => {
        closeModal();
        if (setShowDocSignIn) {
            setShowDocSignIn(true);
        } else {
            navigate("/doctor/home");
        }
    };

    const handleSignUpClick = () => {
        closeModal();
        if (setShowSignUp) {
            setShowSignUp(true);
        } else {
            navigate("/");
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-light-surface rounded-lg shadow-lg w-[90%] max-w-md sm:p-6 relative 
            md:max-w-[670px] md:p-[20px_80px_20px_80px]
            lg:max-w-[949px] lg:max-h-[585px] lg:p-[61px_200px_61px_200px]">
                <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 lg:text-2xl" onClick={closeModal}>
                    &times;
                </button>
                <div className="flex flex-col items-center gap-10 max-sm:px-5 max-sm:py-8">
                    <div className="title flex flex-col sm:w-full sm:flex-row sm:justify-between sm:items-center">
                        <h1 className="text-[28px] font-semibold text-[#1D2366]">Patient Sign in</h1>
                        <p className="text-sm font-medium cursor-pointer">Are you a Doctor? <span onClick={handleDocSignInClick} className="font-normal text-blue-600">Click Here</span></p>
                    </div>
                    <form onSubmit={handleSignIn} className="signin-form w-full lg:px-[47px]">
                        <div className="flex flex-col gap-3 sm:gap-6">
                            <div className="flex flex-col gap-2">
                                <label htmlFor="mobile" className="text-base font-medium text-[#061D2E]">Mobile Number</label>
                                <input type="number" name="mobile" id="mobile" className="w-full h-12 px-5 py-4 rounded text-sm font-normal border" placeholder="Enter your mobile number" onChange={handleInput} required />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="password" className="text-base font-medium text-[#061D2E]">Password</label>
                                <input type="password" name="password" id="password" className="w-full h-12 px-5 py-4 rounded text-sm font-normal border" placeholder="Enter Password" onChange={handleInput} required />
                            </div>
                            {
                                patSignInError && (
                                    <p className="text-red-600">{patSignInError}</p>
                                )
                            }
                            <div className="flex flex-col gap-4 sm:flex-row sm:justify-between sm:gap-">
                                <div className="flex gap-2">
                                    <input type="checkbox" name="rememberMe" id="rememberMe" />
                                    <label htmlFor="rememberMe" className="text-sm font-normal text-[#1D2366]">Remember me</label>
                                </div>
                                <NavLink to="#" className="text-sm font-normal text-blue-600">Forgot password?</NavLink>
                            </div>
                            <button type="submit" disabled={loading} className="w-full h-[54px] rounded-lg text-white text-base font-semibold bg-light-primary flex justify-center items-center gap-1">
                                Sign In {loading && <DotSpinner />}
                            </button>
                        </div>
                        <p className="pt-1 text-sm font-semibold cursor-pointer lg:pt-6">Dont't have an account? <span onClick={handleSignUpClick} className="font-semibold text-blue-600">Sign up here</span></p>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SignIn;
