
import HeroPage from "./HeroPage";
import OurTopServices from "./OurTopServices";
import HowItWorks from "./HowItWorks";
import TypesOfIssues from "./TypesOfIssues";
import PatientTestimonal from "./PatientTestimonals";
import MeetOurExperts from "./MeetOurExperts";
import DoctorCardSection from "./DoctorCardSection";


function Home() {
    return(
        <div className="Home">
            <HeroPage />
            <OurTopServices />
            <HowItWorks />
            <TypesOfIssues/>
            <PatientTestimonal/>
            <MeetOurExperts/>
            <DoctorCardSection />
        </div>
    );
}

export default Home;