import Swal from 'sweetalert2'

export const DuplicateErrorSwal=(err)=>{
    const {status,message} = err.response.data;
    Swal.fire({
        title: `${status}`,
        text: `${message}`,
        icon: 'error',
        confirmButtonText: 'Close'
      })
}

export const NetworkErrorSwal = (err)=>{
    const {code,message} = err;
    Swal.fire({
        title: `${code}`,
        text:`${message}`,
        icon: 'error',
        confirmButtonText: 'Close'
    })
}

export const UnknownErrorSwal = (err)=>{
    const {code, message} = err;
    Swal.fire({
        title: `${code}`,
        text:`${message}`,
        icon: 'error',
        confirmButtonText: 'Close'
        })
}
