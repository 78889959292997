
import DoctorCard from "./DoctorCard";
import DoctorOptionsTab from "./doctor_option_tab/DoctorOptionsTab";
import Schedule from "./schedule/Schedule";

import withAuth from "../components/hoc/withAuth";


function BookAppointment() {
    return(
        <section className="book-appointment min-h-screen w-[88%] mx-auto flex justify-between">
            <div className="left flex flex-col gap-10">
                <DoctorCard />
                <DoctorOptionsTab />
            </div>
            <div className="right">
                <Schedule />
            </div>
        </section>
    )
}


export default withAuth(BookAppointment);