import { useState } from "react";
import axios from "axios";

import { useAuth } from "../../contexts/AuthContext"
import { useNavigate } from "react-router-dom";

function useDoctorSignIn() {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [loading, setLoading] = useState(null);

    async function signin(formData, setDocSignInError) {
        setLoading(true);
      
        try {
          const response = await axios.post("/api/doctor/auth/signin", formData);
          if (response.status === 200) {
            login(response.data.token, response.data.user);
            navigate("/doctor/dashboard");
          }
        } catch (error) {
          setDocSignInError(error.response.data.message || error.message);
        } finally {
          setLoading(false);
        }
      }

    return {loading, signin}
}

export default useDoctorSignIn;