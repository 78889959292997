import React, { useState,useEffect } from 'react';
import axios from "axios";

import { useAuth } from '../../../../../contexts/AuthContext';

import {FillTimeSlotsErrorSwal} from "../../../components/swal-alert/Errors";

const TimeSlots = ({ step, nextStep }) => {

  const {userData} = useAuth();

  const [selectedDays, setSelectedDays] = useState(['MON', 'TUE', 'WED', 'THU', 'FRI']);
  const [timeSlots, setTimeSlots] = useState([{ startTime: '', endTime: '' }]);
  const [duration, setDuration] = useState('');
  const [timeGap, setTimeGap] = useState('');
  const [errors, setErrors] = useState({ days: false, timeSlots: false, overlap: false, invalidTimes: false });

  useEffect(()=>{
    if(!userData) return;
    axios.post("/api/doctor/personal/get-time-slot-details",{doctor_id:userData._id})
    .then(res=>{
        if(res.data.status){
          const {days,time_slots,duration,time_gap} = res.data.details;
          setSelectedDays(days);
          setTimeSlots(time_slots);
          setDuration(duration);
          setTimeGap(time_gap);
        }
    })
    .catch(err=>console.log(err))
  },[])
  
  const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];


  const handleCheckboxChange = (day) => {
    setSelectedDays(prevState =>
      prevState.includes(day) ? prevState.filter(d => d !== day) : [...prevState, day]
    );
  };

  const addTimeSlot = () => {
    setTimeSlots([...timeSlots, { startTime: '', endTime: '' }]);
  };

  const handleTimeChange = (index, type, value) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index][type] = value;

    if (type === 'startTime' && newTimeSlots[index].endTime && newTimeSlots[index].endTime <= value) {
      newTimeSlots[index].endTime = ''; // Reset endTime if it's not valid
    }
    
    setTimeSlots(newTimeSlots);
  };

  const deleteTimeSlot = (index) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots.splice(index, 1);
    setTimeSlots(newTimeSlots);
  };

  const parseTime = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const checkOverlap = () => {
    for (let i = 0; i < timeSlots.length; i++) {
      for (let j = i + 1; j < timeSlots.length; j++) {
        const slot1Start = parseTime(timeSlots[i].startTime);
        const slot1End = parseTime(timeSlots[i].endTime);
        const slot2Start = parseTime(timeSlots[j].startTime);
        const slot2End = parseTime(timeSlots[j].endTime);

        if (
          (slot1Start < slot2End && slot1End > slot2Start) || // Overlap case 1
          (slot2Start < slot1End && slot2End > slot1Start)    // Overlap case 2
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const checkInvalidTimes = () => {
    return timeSlots.some(slot => parseTime(slot.startTime) >= parseTime(slot.endTime));
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = { days: false, timeSlots: false };

    if (selectedDays.length === 0) {
      errors.days = true;
      formIsValid = false;
    }

    if (timeSlots.some(slot => !slot.startTime || !slot.endTime)) {
      errors.timeSlots = true;
      formIsValid = false;
    }


    if (checkInvalidTimes()) {
      errors.invalidTimes = true;
      formIsValid = false;
    }

    if (checkOverlap()) {
      errors.overlap = true;
      formIsValid = false;
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = async (e)=>{
    e.preventDefault();
    if(validateForm()){
      const formData = {
        selectedDays,
        timeSlots,
        duration,
        timeGap,
      };
      
      try{
        const tsDetails = {...formData,doctor_id:userData._id}
        console.log(tsDetails)
        const response = await axios.post("/api/doctor/personal/time-slot-details",tsDetails);
        console.log(response.data);
      } catch (err){
        console.log(err);
      }

      nextStep();
    }else{
      FillTimeSlotsErrorSwal();
    }
  }

  return (
    <>
      {step === 3 && (
        <form className="my-10 lg:mr-32" onSubmit={handleSubmit}>
          <div className="flex flex-col sm:flex-row w-full my-7">
            <div className="p-5 w-full md:border border-gray-800 rounded-xl">
              <label htmlFor="selectdays" className="block font-bold text-xl text-[#061D2E]">
                Select Days <span className="text-xl font-normal text-[#B3261E]">*</span>
              </label>
              <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-7 gap-2 mb-4">
                {days.map(day => (
                  <div key={day}>
                    <input
                      type="checkbox"
                      id={`checkbox-${day}`}
                      className="hidden"
                      checked={selectedDays.includes(day)}
                      onChange={() => handleCheckboxChange(day)}
                    />
                    <label
                      htmlFor={`checkbox-${day}`}
                      className={`inline-block w-full text-center px-6 py-6 text-lg border-2 cursor-pointer transition duration-300 ease-in-out transform
                ${selectedDays.includes(day) ? 'bg-[#658DF1] text-white' : 'bg-[#DAE2FF] text-black'}`}
                    >
                      {day}
                    </label>
                  </div>
                ))}
              </div>
              {errors.days && <p className="text-red-500">Please select at least one day.</p>}
              
              <div className="time-slot relative">
                {timeSlots.map((slot, index) => (
                  <div key={index} className="mb-4 flex flex-col sm:flex-row md:gap-32 items-center">
                    <div>
                      <label htmlFor={`start-time-${index}`} className="block text-xl font-bold text-[#061D2E]">
                        Select Start Time <span className="text-base font-normal text-[rgb(179,38,30)]">*</span>
                      </label>
                      <input 
                        type="time" 
                        id={`start-time-${index}`}
                        value={slot.startTime} 
                        step="300"
                        onChange={(e) => handleTimeChange(index, 'startTime', e.target.value)} 
                        className="text-xl font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[266px] h-[72px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" 
                      />
                    </div>
                    <div>
                      <label htmlFor={`end-time-${index}`} className="block text-xl font-bold text-[#061D2E]">
                        Select End Time <span className="text-base font-normal text-[rgb(179,38,30)]">*</span>
                      </label>
                      <input 
                        type="time"
                        id={`end-time-${index}`}
                        value={slot.endTime} 
                        step="300"
                        onChange={(e) => handleTimeChange(index, 'endTime', e.target.value)} 
                        className="text-xl font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[266px] h-[72px] border-[0.4px] border-[#6F7976] rounded shadow-input-01" 
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => deleteTimeSlot(index)}
                      className="ml-4 bg-red-500 text-white text-lg rounded-full h-10 w-10 flex items-center justify-center"
                    >
                      X
                    </button>
                  </div>
                ))}

                <div className="flex justify-center lg:absolute right-32 bottom-0">
                  <button
                    type="button"
                    onClick={addTimeSlot}
                    className="bg-[#B7FFEC] text-4xl rounded-full h-20 w-20 flex items-center justify-center"
                  >
                    +
                  </button>
                </div>
              </div>
              {errors.timeSlots && <p className="text-red-500">Please fill all time slots with valid times.</p>}
              {errors.invalidTimes && <p className="text-red-500">Start time must be before end time.</p>}
              {errors.overlap && <p className="text-red-500">Time slots cannot overlap.</p>}


              <div className="mb-4">
                <label htmlFor="selectduration" className="block text-xl font-bold text-[#061D2E]">Select Duration <span className="text-base font-normal text-[rgb(179,38,30)]">*</span></label>
                <select 
                id="selectduration" name="selectduration" required 
                value={duration}
                onChange={(e)=>setDuration(e.target.value)}
                className="text-xl font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[266px] h-[72px] border-[0.4px] border-[#6F7976] rounded shadow-input-01">
                  <option value="">Select</option>
                  <option value="5">5 Mins</option>
                  <option value="10">10 Mins</option>
                  <option value="15">15 Mins</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="selecttimegap" className="block text-xl font-bold text-[#061D2E]">Select Time Gap <span className="text-base font-normal text-[#B3261E]">*</span></label>
                <select id="selecttimegap" name="selecttimegap" required 
                value={timeGap}
                onChange={(e)=>setTimeGap(e.target.value)}
                className="h-[72px]  text-xl font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full lg:w-[266px] border-[0.4px] border-[#6F7976] rounded shadow-input-01">
                  <option value="">Select</option>
                  <option value="5">5 Mins</option>
                  <option value="10">10 Mins</option>
                  <option value="15">15 Mins</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex justify-start lg:justify-end">
            <button type="submit" className="px-[70px] py-4 bg-blue-500 text-sm font-medium font-roboto text-white rounded-[100px]">
              Save & Continue
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default TimeSlots;
