
import SignUpForm from './SignUpForm';

import Doctor from '../../asserts/home/doctor_illustration.png'

function HeroSection(){
    return(
        <section className='w-full bg-gradient-to-b from-lime-50 to-emerald-50 px-4 py-2 2xl:flex 2xl:justify-center 2xl:gap-[18px] 2xl:py-12'>
            <div className="left-block grid gap-4 2xl:w-[503px] 2xl:gap-[18px]">
                <h1 className='text-[36px] leading-[44px] font-medium text-[#1C554B] text-center 2xl:text-[32px] 2xl:leading-10 2xl:font-bold'>Welcome to Aushadham! </h1>
                <div className="signup-block bg-[#FFFFFF] py-2 px-1 rounded-[14px] shadow-xl grid gap-5 2xl:p-[26px_24px_26px_24px]">
                    <h3 className='text-[28px] leading-[40px] font-bold text-center text-[#1D2366]  2xl:text-start'>Sign-up Here</h3>
                    <SignUpForm />
                </div>
            </div>
            <div className='right-img hidden 2xl:block'>
                <img src={Doctor} alt="Doctor's hero section" className='py-[56px] ml-[15px] '/>
            </div>
        </section>
    );
}


export default HeroSection;