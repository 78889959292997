import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";

import usePatientSignUp from "../../../../hooks/patient/usePatientSignUp";

import DotSpinner from "../../utils/Spinner";

function SignUpModal({ isOpen, onClose, setShowSignIn }) {

    const {isAuthenticated} = useAuth();

    const [loginDetails, setLoginDetails] = useState({ mobile: "", password: "" });
    const [showRequirements, setShowRequirements] = useState(false);


    useEffect(()=>{
        if(isAuthenticated){
            onClose();
        }
    },[isAuthenticated, onClose])


    const {loading, signup} = usePatientSignUp();
    const handleSignup = async (e) => {
        e.preventDefault();
        setLoginDetails({ ...loginDetails });
        signup(loginDetails);
    };

    const handleInput = (e) => {
        const mobile = e.target.value;
        setLoginDetails({...loginDetails, mobile});
    }

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        setLoginDetails({ ...loginDetails, password });
        setShowRequirements(password.length > 0);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-container bg-light-surface rounded-lg shadow-lg w-[90%] max-w-md sm:p-6 relative 
            md:max-w-[670px] md:p-[20px_80px_20px_80px]
            lg:max-w-[949px] lg:p-[61px_200px_61px_200px]">
                <button onClick={onClose} className="absolute top-2 right-2 text-gray-600">X</button>
                <div className="patient-sign-in w-full flex flex-col justify-start items-end gap-3">
                    <div className="w-full">
                        <div className="flex justify-center px-5 py-8 w-full mx-auto bg-light-surface rounded-3xl">
                            <div className="flex flex-col items-center gap-10 sm:w-[455px]">
                                <div className="title flex flex-col sm:w-full sm:flex-row sm:justify-between sm:items-center">
                                    <h1 className="text-[28px] font-semibold text-[#1D2366]">Patient Sign Up</h1>
                                    <p className="text-sm font-medium">Are you a Doctor? <NavLink to="/doctor/home" className="font-normal text-blue-600">Click Here</NavLink></p>
                                </div>
                                <form onSubmit={handleSignup} className="signin-form w-full lg:px-[47px]">
                                    <div className="flex flex-col gap-3 sm:gap-6">
                                        <div className="flex flex-col gap-2">
                                            <label htmlFor="mobile" className="text-base font-medium text-[#061D2E]">Mobile Number</label>
                                            <input type="number" name="mobile" id="mobile" className="w-full h-12 px-5 py-4 rounded text-sm font-normal border" placeholder="Enter your mobile number" onChange={handleInput} required />
                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <label htmlFor="password" className="text-base font-medium text-[#061D2E]">Create Secure Password</label>
                                            <input type="password" name="password" id="password" className="w-full h-12 px-5 py-4 rounded text-sm font-normal border" placeholder="Enter Password" onChange={handlePasswordChange} required />
                                            {showRequirements && (
                                                <div className="requirement">
                                                    <ul className="text-sm sm:text-base font-normal flex flex-col gap-[1px]">
                                                        <li className="font-medium">Must contain at least</li>
                                                        <li>8 characters</li>
                                                        <li>1 lower case character</li>
                                                        <li>1 special character</li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                        <button type="submit" disabled={loading} className="w-full h-[54px] rounded-lg text-white text-base font-semibold bg-light-primary flex justify-center items-center gap-1">
                                            Confirm {loading && <DotSpinner />}
                                        </button>
                                    </div>
                                    <p className="pt-1 text-sm font-semibold cursor-pointer lg:pt-6">Already have an account? <span onClick={()=>{onClose();setShowSignIn(true);}} className="font-semibold text-blue-600">Sign in here</span></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUpModal;
