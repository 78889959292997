import { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import axios from 'axios';


function DaySlots({dID, totalTS, daySlots, setDaySlots, formData, setFormData, setIsdayLoaded}){

    
    const handleDaySelect = (value)=>{
        setFormData({...formData, day:value})
    }

    const updateAvailableSlots = (data) => {
        const { appointments } = data;
    console.log(appointments)
        // Count booked slots for each day
        const bookedSlotsCount = {};
    
        appointments.forEach(appointment => {
            const day = new Date(appointment.day_slot).toDateString();
            if (!bookedSlotsCount[day]) {
                bookedSlotsCount[day] = 0;
            }
            bookedSlotsCount[day]++;
        });
    console.log(bookedSlotsCount)
        // Subtract booked slots from available slots
        const updatedDaySlots = daySlots.map(slot => {
            const day = new Date(slot.date).toDateString();
            const bookedCount = bookedSlotsCount[day] || 0;
            return {
                ...slot,
                availSlot: typeof totalTS === 'number' ? (totalTS - bookedCount) : 0
            };
        });
    console.log(updatedDaySlots)
        // Update the state with the new available slots
        setDaySlots(updatedDaySlots);
        setIsdayLoaded(true);
    };

    useEffect(()=>{
        axios.post("/api/appointments/get-appointments-slot-by-date", {doctor_id: dID})
        .then(res=>{
            console.log("==========called=============")
            updateAvailableSlots(res.data)
            console.log("==========called=============")
        })
        .catch(err=>{
            console.log(err);
        })
    },[formData.day.date])

    return(
        <div className="date-slots h-[60px] px-2">
            <Swiper spaceBetween={10} slidesPerView={4}>
                {
                    daySlots?.map((data, idx)=>(
                        <SwiperSlide key={idx} className={`today rounded-lg shadow px-3 py-2 flex flex-col gap-1 
                        ${formData.day.date === data.date?"bg-[#658DF1] text-white":`${data.hasSlot?'bg-white text-black':'bg-[#F6F6F6] text-[#A6A6A6]'}`}`}
                        onClick={()=>{data.hasSlot && handleDaySelect(data)}}>
                            <h4 className="text-sm font-semibold text-center capitalize">{data.day}, {data.date.split(' ').slice(0,2).join(' ')}</h4>
                            <p className={`text-xs font-normal text-center ${data.hasSlot?'text-[#99F3DE]':'text-[#A6A6A6]'}`}>{data.hasSlot?data.availSlot:'00'} slots available</p>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            {/* <div className="today bg-[#658DF1] rounded-lg shadow px-3 py-2 flex flex-col gap-1">
                <h4 className="text-sm font-semibold text-center text-white">Mon, 10 Oct</h4>
                <p className="text-xs font-normal text-[#99F3DE] text-center">00 slots available</p>
            </div>
            <div className="today bg-[#fff] rounded-lg shadow px-3 py-2 flex flex-col gap-1">
                <h4 className="text-sm font-semibold text-center text-black">Tue, 11 Oct</h4>
                <p className="text-xs font-normal text-[#FFC649] text-center">00 slots available</p>
            </div>
            <div className="today bg-[#F6F6F6] rounded-lg shadow px-3 py-2 flex flex-col gap-1">
                <h4 className="text-sm font-semibold text-center text-[#A6A6A6]">Wed, 12 Oct</h4>
                <p className="text-xs font-normal text-[#A6A6A6] text-center">00 slots available</p>
            </div>
            <div className="today bg-[#fff] rounded-lg shadow px-3 py-2 flex flex-col gap-1">
                <h4 className="text-sm font-semibold text-center">Thu, 13 Oct</h4>
                <p className="text-xs font-normal text-[#99F3DE] text-center">00 slots available</p>
            </div> */}
        </div>
    );
}


export default DaySlots;