import { useState,useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import HimadriChaudary from "../asserts/doctors/himadriChaudary.png";

import {convertTo12HourFormat} from "../book_appointment/schedule/scheduleHelper";

function CheckOut() {

    const [loading, isLoading] = useState(true);
    const [bookingDetails, setBookingDetails] = useState({});
    const [docDetails, setDocDetails] = useState({});

    const getAppointmentDataFromLS = ()=>{
        const booking_details = JSON.parse(localStorage.getItem("booking_details"));
        setBookingDetails(booking_details);
    }
    
    const getAllDetailsForDoc = async(doctorId)=>{
        try {
            const docAllDetails = await axios.post("/api/details/getDoctorAllInfo",{doctor_id: doctorId});
            setDocDetails(docAllDetails.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        getAppointmentDataFromLS();
    },[])

    useEffect(() => {
        if(bookingDetails){
            getAllDetailsForDoc(bookingDetails.doctor_id);
        } else {
            console.error("no booking details to parse")
        }
        console.log(docDetails);
        isLoading(false);
    },[bookingDetails]);

    if (loading) return

    return(
        <section className="w-screen min-h-screen bg-light-surface">
            <div className="py-10 grid place-items-center place-content-center gap-5">
                <div className="h-[550px] w-[1280px] flex justify-between items-center gap-8">
                    <div className="left w-[583px] flex flex-col items-center gap-4">
                        <h3 className="w-fit text-lg font-medium bg-[#A0F2DE] p-[13px_60px_13px_60px] rounded-sm">{bookingDetails.mode_of_appointment==='offline'?'In-clinic':'Online'} Appointment</h3>
                        <div className="w-full bg-white px-12 py-6 flex flex-col gap-8">
                            <h5 className="text-base font-semibold">Confirm your appointment with</h5>
                            <div className="grid gap-6">
                                <div className="doc-card flex items-center gap-[6px]">
                                    <img src={HimadriChaudary} alt="Dr. Himadri Chauday" className="w-20 h-20 rounded-[40px]" />
                                    <div className="doc details flex flex-col gap-1">
                                        <h3 className="text-[22px] leading-7 font-medium">{docDetails.full_name}</h3>
                                        <h4 className="text-sm font-medium">{docDetails.practice}</h4>
                                    </div>
                                </div>
                                <div className="time grid gap-2">
                                    <p className="text-base font-normal">Time</p>
                                    <p className="text-2xl font-medium">{Object.keys(bookingDetails).length>0 && convertTo12HourFormat(bookingDetails.time)}, {bookingDetails.date}</p>
                                    <p className="text-lg font-bold text-light-secondary underline">Change timings</p>
                                </div>
                                <div className="address grid gap-2">
                                    <p className="text-base font-normal">Address</p>
                                    <p className="text-2xl font-medium">{docDetails.clinic_name}, {docDetails.street_name}, {docDetails.city}.</p>
                                    <p className="text-lg font-bold text-light-secondary underline">Get Directions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right grid gap-4">
                        <label htmlFor="symptoms" className="text-[22px] leading-7 font-medium">
                            Explain your symptoms <span className="text-sm font-normal">(Optional)</span>
                        </label>
                        <textarea name="" id="symptoms" className="w-[522px] h-[193px]"></textarea>
                        <p className="text-base font-normal">
                            &#x1F6C8; Describing your symptoms will help doctor understand your state from before
                        </p>
                    </div>
                </div>
                <div className="w-[844px] flex justify-between">
                    <button className="w-[124px] h-11 rounded-[100px] border border-[#6F7976] text-light-primary text-sm font-medium font-roboto">
                        <Link to="/confirmDetails">Back</Link>
                    </button>
                    <button className="w-[142px] h-11 rounded-[100px] bg-light-primary text-white text-sm font-medium font-roboto">Pay Now</button>
                </div>
            </div>
        </section>
    );
}

export default CheckOut;