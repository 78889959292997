import AboutTabContent from "./tab_contents/AboutTabContent";

const TabsDetailsList = [
    {
        id:1,
        tite:"About",
        content:<AboutTabContent />,
    },
    {
        id:2,
        tite:"Reviews",
        content:"Reviews Tab Content",
    },
    {
        id:3,
        tite:"Ask a Question",
        content:"Ask a Question Tab Content",
    },
    {
        id:4,
        tite:"Blog",
        content:"Blog Tab Content",
    },
]


export default TabsDetailsList;