
import { FiArrowRight } from "react-icons/fi";
import { MdTune } from "react-icons/md";

import { MdLocationOn } from "react-icons/md";

import heroRightImg from "../asserts/home/Hero-image-right.png"


// new desings

function Search(){
    return(
        <div className="w-screen bg-gradient-homeSearch px-4 py-10 2xl:px-[318px]">
            <div className="flex flex-col justify-center items-center gap-6 sm:gap-[23px] 2xl:mx-auto">
            <h4 className="w-[90.5%] text-[#02604f] text-sm font-semibold text-center 
            sm:w-[91.8%] sm:font-bold sm:text-[28px] sm:leading-9 
            2xl:text-[32px] 2xl:leading-10">
                Find the best doctors, idle healthcare providers and book your appointment today
            </h4>
            <div className="search-box flex flex-col gap-2 sm:flex-row sm:w-full 2xl:w-[94.53%]">
                <div className="location relative h-11 flex items-center sm:basis-1/4 sm:h-[54px]">
                    <select className="w-full h-full text-sm font-normal border border-[#E9E9E9] px-[84px] py-1 rounded-lg bg-white sm:text-base sm:text-center sm:px-3 sm:py-1">
                        <option value="">Select Location</option>
                        <option value="1">India</option>
                        <option value="2">Russia</option>
                        <option value="3">USA</option>
                    </select>
                    <span className="absolute left-16 sm:left-1">
                        <MdLocationOn />
                    </span>
                </div>
                <div className="search-bar flex gap-[5px] sm:gap-2 sm:basis-3/4">
                    <div className="relative h-11 w-full flex items-center sm:h-[54px]">
                        <input type="text" placeholder="eg. Doctor, specialisation" className="w-full h-full text-xs font-normal border border-[#E7E7E7] px-2 py-[10px] rounded-[10px] sm:text-base" />
                        <span className="absolute right-2 text-[#989898] text-2xl">
                            <FiArrowRight />
                        </span>
                    </div>
                    <button className="w-11 h-11 p-2 text-[#A7A7A7] text-2xl bg-white border border-[#EAEAEA] rounded-lg flex justify-center items-center sm:w-[54px] sm:h-[54px] sm:rounded-full"> 
                        <MdTune /> 
                    </button>
                </div>
            </div>
        </div>
        </div>
    );
}

function Hero(){
    return(
        <div className="hero-section flex flex-col-reverse justify-center items-center px-4 pb-10
        2xl:flex-row 2xl:min-w-[86.11%] 2xl:h-[563px] 2xl:justify-between 2xl:relative 2xl:pb-0 2xl:mx-14 2xl:my-0">
            <div className="hero-section-left w-[94.51%] flex flex-col justify-center items-center gap-8 
            sm:w-[72.90%] sm:px-6 sm:py-4
            2xl:items-start 2xl:w-[44%]">
                <div className="content flex flex-col gap-[21px] sm:gap-5 sm:justify-center sm:items-center 2xl:w-[117%] 2xl:items-start">
                    <h1 className="text-[32px] leading-[39px] text-center font-extrabold font-roboto 
                    sm:text-5xl sm:leading-[72px]
                    2xl:text-start 2xl:text-[64px] 2xl:leading-[78px]">
                        <span className="text-DarkBlue">Consult Doctors </span>
                        <span className="text-Green">Anytime, Anywhere</span>
                    </h1>
                    <p className="test-base font-normal font-roboto text-[#686868]
                     sm:w-[86.67%] sm:text-lg sm:text-center
                     2xl:text-start 2xl:text-[22px] 2xl:leading-9">
                        Skip the Waiting Room and Connect with Top Doctors Across India via Video Call or 
                        In-Clinic Visit. <span className="sm:block">Your Health, Your Schedule!</span>
                    </p>
                </div>
                <button className="w-9/12 px-6 py-[13px] text-white text-base font-medium bg-Green rounded-[35px] sm:px-10 sm:py-5 sm:text-2xl">
                    Book an Appointment
                </button>
            </div>
            <div className="hero-section-right 2xl:absolute 2xl:right-4 2xl:bottom-0">
                <img src={heroRightImg} alt="landing page doctors" className="w-[244px] h-[202px] sm:w-[720px] sm:h-[487.26px] 2xl:w-[761px] 2xl:h-[515px]" />
            </div>
        </div>
    );
}

function HeroPage(){
    return(
        <section className="w-screen min-h-screen bg-[#FEFAF2]">
            {/* <div className="mobile 2xl:hidden">
                <SearchSectionMob />
                <HeroSectionMob />
            </div>
            <div className="pc hidden 2xl:block">
                <SearchSection />
                <HeroSection />
            </div> */}
            <Search />
            <Hero />
        </section>
    );
}


export default HeroPage;