

function ModeOfAppointment({formData, setFormData}){

    const handleModeSelect = (value)=>{
        setFormData({...formData, mode_of_appointment: value})
    }

    return(
        <div className="status flex">

            <div className={`w-[275px] h-16 rounded-tl-lg px-[110px] py-[22.5px] text-center
                ${formData.mode_of_appointment === 'offline'?'bg-[#658DF1] text-white':'bg-[#E7E7E7]'}`}
                onClick={()=>{handleModeSelect('offline')}}>
                    Offline
            </div>
            <div className={`w-[275px] h-16 rounded-tr-lg px-[110px] py-[22.5px] text-center
                ${formData.mode_of_appointment === 'online'?'bg-[#658DF1] text-white':'bg-[#E7E7E7]'}`}
                onClick={()=>{handleModeSelect('online')}}>
                    Online
            </div>

            {/* <p className="w-[275px] h-16 bg-[#658DF1] text-white rounded-tl-lg px-[110px] py-[22.5px]">Offline</p>
            <p className="w-[275px] h-16 bg-[#E7E7E7] text-black rounded-tr-lg px-[110px] py-[22.5px]">Online</p> */}
        </div>
    );
}

export default ModeOfAppointment;