import React, { useState } from 'react';

import PersonalDetailsForm from './PersonalDetailsForm';
import RegistrationDetailsForm from './RegistrationDetailsForm';
import WorkDetails from "./WorkDetails";


const StepperForm = () => {
    const [step, setStep] = useState(1);
  
    const nextStep = () => {
      setStep(step + 1);
    };
  
    const prevStep = () => {
      setStep(step - 1);
    };

    const goToStep = (s)=>{
      setStep(s);
    }
  
    return (
    <>
        <div className={`lg:${step===3?'hidden':'grid'} hidden grid-cols-3 mt-8 w-[880px]`}>
          {["Personal Details","Registration Details","Work Details"].map((item,idx) => (
              <div key={idx} className={`${idx+1 === step ? "bg-[#B7FFEC]":"" } w-[262px] h-16 rounded`}>
                <div className="flex flex-row justify-stretch gap-4 px-1 text-[18px] font-[500] ">
                  <p className="rounded-full w-[60px] h-[60px] text-center text-3xl text-black p-3 bg-[#DAE2FF]">
                  {idx+1}
                  </p>
                  <p className="py-4">{item}</p>
                </div>
              </div>
          ))}
        </div>
        <div className={`hidden w-[880px] h-[30px] bg-[#EEF0FF] ${step===3?'hidden':'flex'} justify-start rounded-full gap-60 lg:gap-44 flex-row ${step === 1 || step === 3 ? "gap-80" : ""}`}>
          {step === 2 ? (
            <>
              <div className="w-60 bg-[#658DF1] h-[30px] rounded-full"></div>
              <div>
                <p>30% Complete</p>
              </div>
            </>
          ) : step === 3 ? (
            <>
              <div className="w-11/12 bg-[#658DF1] h-[30px] rounded-full text-center">
                <p className='text-white'>90% Complete</p>
              </div>
            </>
          ) : (
            <>
              <div className="w-10 bg-[#658DF1] h-[30px] rounded-full"></div>
              <div>
                <p>Fill the details for complete set-up</p>
              </div>
            </>
          )}
        </div>

        
        <ol className="lg:hidden flex items-center justify-center w-full text-xs text-gray-900 font-medium sm:text-base">
          {["Personal Details","Registration Details","Work Details"].map((item,idx) => (
            <li key={idx} className={`flex w-full ${idx===0?"justify-start":(idx===1)?"justify-center":(idx===2)?"justify-end":""} relative text-gray-900 ${idx < 2 ? 'after:content-[""] after:w-full after:h-0.5 after:bg-gray-200 after:inline-block after:absolute lg:after:top-5 after:top-5 after:left-[45%]' : ''} `}>
              <div className="block w-16 break-normal text-sm font-medium text-[#061D2E] text-center z-10">
                <span className={`w-10 h-10 ${idx+1 === step ? 'bg-[#006B5B] text-white' : 'bg-[#DAE2FF] text-gray-900'} border-2 ${idx+1 === step ? 'border-transparent' : 'border-gray-200'} rounded-full flex justify-center items-center mx-auto mb-3 text-sm lg:w-10 lg:h-10`}>
                  {idx+1}
                </span>
                {item}
              </div>
            </li>
          ))}
        </ol>
  
  
        <div className="mt-4">
          
          <PersonalDetailsForm step={step} nextStep={nextStep} />
          <RegistrationDetailsForm step={step} nextStep={nextStep} goToStep={goToStep} />
              
          {step === 3 && (
            <form className='mb-10'>
              <WorkDetails />
            </form>
          )}
        </div>
    </>
    );
  };
  
  export default StepperForm;