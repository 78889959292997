import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {useAuth} from "../../../../contexts/AuthContext";

function TimeSlots({dID, timeSlots, setTimeSlots, formData, setFormData}){
    const navigate = useNavigate();
    const {userData} = useAuth();

    const updateTimeSlotAvailability = (schedule, BookedSlots) => {
        const updatedSchedule = { ...schedule };
        for (const period in updatedSchedule) {
            updatedSchedule[period] = updatedSchedule[period].map(slot => {
                return {
                    ...slot,
                    isAvail: !BookedSlots.includes(slot.value)
                };
            });
        }
        setTimeSlots(updatedSchedule);
    };

    useEffect(()=>{
        if(formData.day.date){
        axios.post("/api/appointments/get-appointments-by-day",{doctor_id: dID, date: formData.day.date})
        .then(res=>{
            console.log(res.data);
            updateTimeSlotAvailability(timeSlots, res.data.time_slots);
            console.log(timeSlots);
        })
        .catch(err=>console.log(err));
        }
        
        const currTime = JSON.parse(localStorage.getItem("booking_details"));
        if(currTime){
            setFormData(prev=>({...prev, time:currTime.time}))
        }
    },[formData.day.date])


    const handleTimeSelect = async (value)=>{
        await setFormData(prevFormData => ({
            ...prevFormData,
            time: value
        }));
        
        try {

            const bookingData = {
                doctor_id: dID,
                patient_id: userData.patient_id,
                date: formData.day.date,
                time: value,
                mode_of_appointment: formData.mode_of_appointment
            }

            const response = await axios.post("/api/appointments/book", bookingData);
            console.log('Booking response:', response.data);
            localStorage.setItem("booking_details", JSON.stringify(bookingData))
            navigate("/confirmDetails");

        } catch (error) {
            console.error('Error booking appointment:', error);
        }
    }

    return(
        <div className="timing w-[534px] mx-auto grid gap-4">

                    {
                        Object.keys(timeSlots)?.map((period, idx)=>(
                            <div key={idx} className={`${period}-time grid gap-2`}>
                                {timeSlots[period].length>0 && (
                                    <>
                                    <h4 className="text-base font-medium">{period}</h4>
                                    <div className="flex justify-start flex-wrap gap-x-3 gap-y-4">
                                        {
                                            timeSlots[period].map((time,ti)=>(
                                                <div key={ti} className="w-[92px] h-[42px]">
                                                    <label htmlFor={`${ti}-${time.value}`} 
                                                    className={`w-full text-black font-normal text-sm text-center rounded p-[12.5px] shadow
                                                    ${formData.time === time.value?'bg-[#658DF1] text-white':`${time.isAvail?'bg-white hover:bg-[#4985FFC4] hover:text-white':'bg-[#D0D0D0] text-[#E7E7E7]'}`}`}
                                                    >
                                                        {time.label}
                                                    </label>

                                                    { time.isAvail &&
                                                    (<input type="radio" 
                                                    name="time-slot" 
                                                    id={`${ti}-${time.value}`} 
                                                    className="hidden"
                                                    checked={formData.time === time.value}
                                                    disabled={!time.isAvail}
                                                    onChange={()=>{handleTimeSelect(time.value)}}
                                                    />)
                                                    }
                                                    
                                                </div>
                                            ))
                                        }
                                    </div>
                                    </>
                                )}
                            </div>
                        ))
                    }
                </div>
    );
}

export default TimeSlots;