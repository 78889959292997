import { useState } from "react";
import { NavLink } from "react-router-dom";

import { useAuth } from "../../../../contexts/AuthContext";

import { IoMdSearch } from "react-icons/io";
import { LuMenu } from "react-icons/lu";
import { IoIosClose } from "react-icons/io";

import NavLogo from "../../asserts/aushadamNavLogo.jpg";
import "./Navbar.css";

import SignIn from "../../../components/auth/patient/SignIn"
import SignUpModal from "../../../components/auth/patient/SignUp";
import DoctorSignIn from "../../../components/auth/doctor/SignIn"

function Navbar() {
    const { isAuthenticated, logout } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const [showSignIn, setShowSignIn] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [showDocSignIn, setShowDocSignIn] = useState(false);

    return (
        <>
            <nav className="w-full h-[79.1px] flex items-center justify-between bg-light-surface lg:bg-white lg:w-screen lg:justify-around">
                <div className="navBrand">
                    <img src={NavLogo} alt="Aushadham" className="w-[86px] h-[54.17px] rounded-[148px] lg:rounded-none" />
                </div>
                <div className={`navItems w-full h-[336px] max-lg:absolute top-20 z-20 p-2 ${isOpen ? 'block' : 'hidden'} bg-light-surface lg:bg-white lg:h-auto lg:w-auto lg:flex lg:items-center lg:justify-between`}>
                    <ul className="text-poppins bg-light-surface text-[#171D1B] text-lg font-normal whitespace-nowrap flex flex-col gap-3 items-center max-lg:h-screen lg:bg-white lg:flex-row lg:gap-8">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/doctors">Doctors</NavLink></li>
                        <li>Hospitals</li>
                        <li>About Us</li>
                        <li className="text-Green max-lg:hidden"><IoMdSearch /></li>
                        <li className="lg:hidden" onClick={() => setShowSignUp(true)}>Sign up</li>
                        <li className="lg:hidden text-Green" onClick={() => setShowSignIn(true)}>Sign in</li>
                    </ul>
                </div>
                <div className="navMenu text-3xl font-semibold lg:hidden">
                    <button onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? <IoIosClose /> : <LuMenu />}
                    </button>
                </div>
                {
                    !isAuthenticated ? (
                        <div className="navAuth hidden lg:w-[248px] lg:h-[44px] lg:flex gap-2">
                            <button onClick={() => setShowSignIn(true)} className="border-2 border-Green px-2 py-1 rounded-3xl lg:w-[97px] lg:px-[10px] lg:py-[20px] lg:flex lg:items-center lg:justify-center">Sign in</button>
                            <button onClick={() => setShowSignUp(true)} className="bg-Green text-white px-2 py-1 rounded-3xl lg:w-[135px] lg:px-[10px] lg:py-[20px] lg:flex lg:items-center lg:justify-center">Sign-up</button>
                        </div>
                    ) : (
                        <div className="navAuth hidden lg:w-[248px] lg:h-[44px] lg:flex gap-2">
                            <button className="border-2 border-Green px-2 py-1 rounded-3xl lg:w-[97px] lg:px-[10px] lg:py-[20px] lg:flex lg:items-center lg:justify-center" onClick={() => { logout() }}>Logout</button>
                        </div>
                    )
                }
            </nav>
            {showSignIn ? <SignIn closeModal={() => setShowSignIn(false)} setShowSignUp={setShowSignUp} setShowDocSignIn={setShowDocSignIn} /> : null}
            {showSignUp ? <SignUpModal isOpen={showSignUp} onClose={() => setShowSignUp(false)}  setShowSignIn={setShowSignIn} /> : null}
            {showDocSignIn ? <DoctorSignIn closeModal={() => setShowDocSignIn(false)} /> : null}
        </>
    );
}

export default Navbar;
