
import { LuMenu } from "react-icons/lu";

import NavBrand from "../../asserts/aushadamNavLogo.jpg";

function TopNav() {
    return(
        <div className="lg:hidden TopNav w-full h-[48.86px]">
            <nav className="w-[92%] mx-auto flex justify-between items-center">
                <div className="brand">
                    <img src={NavBrand} className="w-[60px] h-[32.86px]" alt="Aushadham" />
                </div>
                <div className="menu">
                    <LuMenu />
                </div>
            </nav>
        </div>
    );
}


export default TopNav;