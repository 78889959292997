import React, { useState,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import { useAuth } from '../../../../../contexts/AuthContext';

import ph_hospital from '../../../asserts/personal-details/ph_hospital.svg';
import profile_details from '../../../asserts/personal-details/profile_details.png';

const ProfileDetails = ({ step }) => {
    const navigate = useNavigate();
    const {userData} = useAuth();

    const [selectedImage, setSelectedImage] = useState(profile_details);
    const [imageFile, setImageFile] = useState(null);
    const pFormRef = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (imageFile) {
            const formData = new FormData();
            formData.append('doctor_id', userData._id);
            formData.append('profileImage', imageFile);

            for (let [key, value] of formData.entries()) {
                console.log(key, value); // Log FormData entries
            }

            try {
                console.log(formData)
                const response = await axios.post('api/doctor/personal/profile-photo', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.status === 200) {
                    console.log('Image uploaded successfully');
                    navigate("/doctor/dashboard")
                } else {
                    console.error('Failed to upload image');
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        } else {
            console.error('No image selected');
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
            setImageFile(file);
        } else {
            console.error('Invalid file type');
        }
    };

    return (
        <>
            {step === 4 && (
                <form className='mb-10' onSubmit={handleSubmit} ref={pFormRef}>
                    <div className="flex flex-col md:flex-row w-full justify-between mt-20">
                        <div className="p-5 w-auto">
                            <div className='bg-[#FCFCFA] shadow-lg w-[242px] h-[242px] p-5 mx-auto md:mx-0'>
                                <img src={selectedImage} name="doctor_profile" alt='Profile Details' />
                            </div>
                            <div className="flex flex-col gap-2 lg:w-64">
                                <label htmlFor="profileImage" className="text-base font-medium text-[#061D2E] flex flex-col">
                                    <p className="bg-[#DAE2FF] p-3 text-center mt-4 rounded-sm">Upload your picture</p>
                                </label>
                                <p className="text-sm">Make sure your image is clear and clearly recognized</p>
                                <input type="file" id="profileImage" name="profileImage" required className="hidden h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] w-full border-[0.4px] border-[#6F7976] rounded shadow-input-01" onChange={handleImageChange} />
                            </div>
                        </div>
                        <div className="h-auto w-auto lg:block hidden">
                            <div className='bg-[#A0F2DE29] lg:w-[480px] flex flex-col gap-8 px-3 py-6 rounded-lg shadow-lg shadow-[#006B5B33]'>
                                <div className='flex justify-center'>
                                    <img src={ph_hospital} alt='hospital svg' />
                                </div>
                                <div className='flex flex-col gap-4'>
                                    <p className='text-3xl font-bold'>Add your profile picture</p>
                                    <p className='text-base'>Please add your recent profile picture so that patient can identify you and it helps in building trust</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-start lg:justify-end">
                        <button type="submit" className="px-[70px] py-4 bg-blue-500 text-sm font-medium font-roboto text-white rounded-[100px]">
                            Submit
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};

export default ProfileDetails;
