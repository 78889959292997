import { useState,useRef } from "react";

import useDoctorSignUp from "../../../../hooks/doctor/useDoctorSignUp";


import { GoEye,GoEyeClosed } from "react-icons/go";

import DoctorSignIn from "../../../components/auth/doctor/SignIn";
import DotSpinner from "../../../components/utils/Spinner";

function SignUpForm(){

    const [showDocSignIn, setShowDocSignIn] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);

    const [formData, setFormData] = useState({
        full_name:"",
        mobile:"",
        password:"",
        termsAndConditions:false
    });

    function handleInput(e) {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }

    const confirmPasswordRef = useRef(null);
    const [confirmPasswordInfo, setConfirmPasswordInfo] = useState("");
    function checkConfirmPassword() {
        const confirmPassword = confirmPasswordRef.current.value;
        if (formData.password !== confirmPassword) {
            setConfirmPasswordInfo("Password not same");
            return false;
        }
        return true;
    }

    const {loading, signup} = useDoctorSignUp();

    function handleSubmit(e) {
        e.preventDefault();
        if (checkConfirmPassword()) {
            signup(formData);
        }
        else {
            console.log("Password not same");
        }
    }


    return(
        <>
        <form onSubmit={handleSubmit} className="signup-form text-sm font-medium flex flex-col gap-4">
            <div className='flex flex-col gap-1'>
                <label htmlFor="full_name" className='text-[#061D2E]'>Full Name</label>
                <input type="text" id="full_name" name="full_name" className='p-[15.5px_20px_15.5px_12px] rounded border-[0.4px] border-[#6F7976]' placeholder="Enter Full Name" required onChange={handleInput} />
            </div>
            <div className='flex flex-col gap-1'>
                <label htmlFor="mobile" className='text-[#061D2E]'>Mobile Number</label>
                <input type="text" id="mobile" name="mobile" className='p-[15.5px_20px_15.5px_12px] rounded border-[0.4px] border-[#6F7976]' placeholder="Enter your Mobile Number" required onChange={handleInput} pattern="[0-9]{10}" />
            </div>
            <div className='flex flex-col gap-1'>
                <label htmlFor="password" className='text-[#061D2E]'>Password</label>
                <div className="password-input relative">
                    <input 
                    type={viewPassword?"text":"password"} 
                    id="password" name="password" 
                    className='w-full p-[15.5px_20px_15.5px_12px] rounded border-[0.4px] border-[#6F7976]' 
                    placeholder="Enter Password" required 
                    onChange={handleInput} 
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$" />
                    <span className="text-[#757776] absolute right-4 top-1/2 -translate-y-1/2">
                        {
                            viewPassword?
                            <GoEyeClosed onClick={()=>setViewPassword(false)} />
                            : 
                            <GoEye onClick={()=>setViewPassword(true)} />
                        }
                    </span>
                </div>
                <p className='text-xs font-light text-[#061D2E]'>
                    Note: Make sure your password is minimum 8 characters with mix of Uppercase &  lowercase alphabets, numbers and special characters [@,$,!,%,*,?,&].
                </p>
            </div>
            <div className='flex flex-col gap-1'>
                <label htmlFor="confirmPassword" className='text-[#061D2E]'>Confirm Password</label>
                <div className="confirm-password-input relative">
                    <input 
                    type={viewPassword?"text":"password"} 
                    id="confirmPassword" 
                    className='w-full p-[15.5px_20px_15.5px_12px] rounded border-[0.4px] border-[#6F7976]' 
                    placeholder="Enter Password" required 
                    onChange={(e) => {
                        setConfirmPasswordInfo("");
                        confirmPasswordRef.current.value = e.target.value;
                        }} 
                    ref={confirmPasswordRef} />

                    <span className="text-[#757776] absolute right-4 top-1/2 -translate-y-1/2">
                        {
                            viewPassword?
                            <GoEyeClosed onClick={()=>setViewPassword(false)} />
                            : 
                            <GoEye onClick={()=>setViewPassword(true)} />
                        }
                    </span>
                </div>
                <p className="text-red-700">{confirmPasswordInfo}</p>
            </div>
            <div className='flex justify-center items-center gap-4 2xl:justify-start'>
                <input type="checkbox" id="termsAndConditions" name="termsAndConditions" required onChange={handleInput} />
                <label htmlFor="termsAndConditions" className="text-xs font-medium">
                    I agree with <span href="#" className='text-[#4973D5] underline'>Terms and Conditions</span>
                </label>
            </div>
            <button type="submit" disabled={loading} className='px-[120px] py-[15px] text-[20px] leading-[24.2px] font-semibold text-white rounded-[30px] bg-[#228674] flex justify-center items-center gap-1'>
                Sign Up {loading && <DotSpinner />}
            </button>
            <p className='text-xs font-normal text-center 2xl:text-sm 2xl:text-start'>
                Already have an account? <span className='font-semibold text-[#4973D5]' onClick={()=>setShowDocSignIn(!showDocSignIn)}>Login here</span>
            </p>
        </form>

        {showDocSignIn ? <DoctorSignIn closeModal={() => setShowDocSignIn(false)} /> : null}
    </>
    );
}


export default SignUpForm;