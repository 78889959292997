import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useAuth } from "../../../contexts/AuthContext";

function DoctorDashboard(){
    const navigate = useNavigate();
    const {isAuthenticated, isLoading, userData} = useAuth();

    const [isCompLoad, setIsCompLoad] = useState(false);

    useEffect(()=>{
        if(!isLoading){
            if(!isAuthenticated){
                navigate("/doctor/home")
            } else {
                if(!userData.hasOwnProperty("patient_id")){
                    setIsCompLoad(true)
                    axios.get("api/doctor/personal/check-profile-complete/"+userData._id)
                    .then(res=>{
                        if (!res.data) {
                            navigate("/doctor/dashboard/profile/registration-details");
                        }
                    })
                    .catch(err=>console.log(err))
                } else{
                    navigate('/')
                }
            }
        }
    },[isAuthenticated, isLoading])

    return(
        <>
        {
            isCompLoad ? (
                <section className="px-4 py-16 text-center">
                    <h1>Welcome DR. {userData?.full_name} !</h1>
                </section>
            ) : (
                <p>loading...</p>
            )
        }
        </>
    );
}

export default DoctorDashboard;