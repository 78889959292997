import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAuth } from "../../../../../contexts/AuthContext";
import FileUploadModal from "../../../../components/modal/FileUploadModal"; // Import the modal component

const state_council_list = ["Medical Council of India","Andhra Pradesh Medical Council","Arunachal Pradesh Medical Council","Assam Medical Council","Bihar Medical Council","Chattisgarh Medical Council","Delhi Medical Council","Goa Medical Council","Gujarat Medical Council","Haryana Medical Council","Himachal Pradesh Medical Council","Jammu & Kashmir Medical Council","Jharkhand Medical Council","Karnataka Medical Council","Madhya Pradesh Medical Council","Maharashtra Medical Council","Manipur Medical Council","Mizoram Medical Council","Nagaland Medical Council","Orissa Council of Medical Registration","Punjab Medical Council","Rajasthan Medical Council","Sikkim Medical Council","Tamil Nadu Medical Council","Telangana State Medical Council","Travancore Cochin Medical Council, Trivandrum","Tripura State Medical Council","Uttarakhand Medical Council","Uttar Pradesh Medical Council","West Bengal Medical Council"];

const RegistrationDetailsForm = ({ step, nextStep, goToStep }) => {
  const { userData } = useAuth();
  const [RDvalue, setRDvalue] = useState({});
  const formRef = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadField, setUploadField] = useState("");
  const [fileNames, setFileNames] = useState({});
  const [selectedFiles, setSelectedFiles] = useState({});

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("doctor_id", userData._id);

    // Append other form data from formRef
    const formElements = formRef.current.elements;
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      if (element.name && element.type !== 'file') {
        formData.append(element.name, element.value);
      }
    }

    // Append selected files
    for (let key in selectedFiles) {
      formData.append(key, selectedFiles[key]);
    }

    for (let key in RDvalue) {
      formData.append(key, RDvalue[key]);
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    await axios.post("/api/doctor/registration/register-details", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      console.log(response.data);
      nextStep();
    }).catch(error => {
      console.error(error);
    });
  };

  useEffect(() => {
    if (!userData) return;

    axios.get("/api/doctor/registration/check-register/" + userData._id)
      .then(res => {
        console.log(step);
        console.log(res.data);
        if (res.data.status) goToStep(3);
      })
      .catch(error => {
        console.log(error);
      });
  }, [userData, step, goToStep]);

  const handleFileSelect = (file) => {
    if (uploadField) {
      setSelectedFiles(prevSelectedFiles => ({ ...prevSelectedFiles, [uploadField]: file }));
      setFileNames(prevFileNames => ({ ...prevFileNames, [uploadField]: file.name }));
      console.log("File selected for field:", uploadField, "with file:", file.name); // Log for debugging
    }
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1980 + 1 }, (v, k) => 1980 + k);


  return (
    <>
      {step === 2 && (
        <form className="mb-10 lg:mr-32" ref={formRef} onSubmit={handleFormSubmit}>
          <div className="medicalInfo flex flex-col gap-[19px] lg:bg-[#FCFCFA] lg:p-5 lg:rounded-lg lg:border border-[#6F7976]">
            <h2 className="text-lg font-medium text-[#171D1B]">Please provide your Medical Registration information</h2>
            <div className="flex flex-col lg:flex-row gap-3 lg:gap-20">
              <div className="flex flex-col gap-2 lg:w-64">
                <label htmlFor="registration_number" className="block text-base font-medium text-[#061D2E]">Registration Number</label>
                <input type="text" id="registration_number" name="registration_number" required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full border-[0.4px] border-[#6F7976] rounded shadow-input-01" placeholder="Ex: APMC/145xxx/3xxxx" />
              </div>
              <div className="flex flex-col gap-2 lg:w-64">
                <label htmlFor="state_council" className="block text-base font-medium text-[#061D2E]">State Council</label>
                <select id="state_council" name="state_council" required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full border-[0.4px] border-[#6F7976] rounded shadow-input-01">
                  <option value="">Select Council</option>
                  {
                    state_council_list.map((data, idx) => (
                      <option key={idx} value={data}>{data}</option>
                    ))
                  }
                </select>
              </div>
              <div className="flex flex-col gap-2 lg:w-64">
                <label htmlFor="year" className="block text-base font-medium text-[#061D2E]">Year</label>
                <select id="year" name="year" required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full border-[0.4px] border-[#6F7976] rounded shadow-input-01">
                  <option value="">Select Year</option>
                  {
                    years.map(year=>(
                      <option key={year} value={year}>{year}</option>
                    ))
                  }
                  <option value="2013">2013</option>
                  <option value="2014">2014</option>
                </select>
              </div>
            </div>
          </div>

          <div className="clear-both h-8 bg-transparent"></div>

          <div className="documentUpload flex flex-col gap-[19px] lg:bg-[#FCFCFA] lg:p-5 lg:rounded-lg lg:border border-[#6F7976]">
            <h2 className="text-lg font-medium text-[#171D1B]">Please provide the following documents to verify</h2>
            <div className="flex flex-col lg:flex-row gap-3 lg:gap-20">
              <div className="flex flex-col gap-2 lg:w-64">
                <label htmlFor="id_proof" className="block text-base font-medium text-[#061D2E]">Choose Identity Proof</label>
                <select id="id_proof" name="id_proof" required className="h-12 text-sm font-normal mt-2 p-[15.5px_20px_15.5px_12px] block w-full border-[0.4px] border-[#6F7976] rounded shadow-input-01">
                  <option value="">Select ID Proof</option>
                  <option value="aadhar-card">Aadhar Card</option>
                  <option value="voter-id">Voter ID</option>
                  <option value="passport">Passport</option>
                  <option value="driving-licence">Driving Licence</option>
                </select>
              </div>
              <div className="flex flex-col gap-2 lg:w-64">
                <label
                  htmlFor="id_proof_file"
                  className="text-base font-medium text-[#061D2E] flex flex-col cursor-pointer"
                  onClick={() => {
                    setUploadField("id_proof_file");
                    setModalOpen(true);
                  }}
                >
                  <p>Upload Identity Proof</p>
                  <p className="bg-[#DAE2FF] p-3 text-center mt-4 rounded-sm">Upload File</p>
                </label>
                <p className="id_proof_file-file-name text-sm text-[#AEA9B1]">{fileNames["id_proof_file"] || "Ex: my_aadhar.pdf"}</p>
                {/* <input type="file" id="id_proof_file" name="id_proof_file" className="hidden" /> */}
              </div>
              <div className="flex flex-col gap-2 lg:w-64">
                <label
                  htmlFor="medical_registration_proof_file"
                  className="text-base font-medium text-[#061D2E] flex flex-col cursor-pointer"
                  onClick={() => {
                    setUploadField("medical_registration_proof_file");
                    setModalOpen(true);
                  }}
                >
                  <p>Medical Registration Proof</p>
                  <p className="bg-[#DAE2FF] p-3 text-center mt-4 rounded-sm">Upload File</p>
                </label>
                <p className="medical_registration_proof_file-file-name text-sm text-[#AEA9B1]">{fileNames["medical_registration_proof_file"] || "Ex: registration.pdf"}</p>
                {/* <input type="file" id="medical_registration_proof_file" name="medical_registration_proof_file" className="hidden" /> */}
              </div>
            </div>
          </div>
          <div className="flex justify-start lg:justify-end">
            <button type="submit" className="px-[70px] py-4 bg-blue-500 text-sm font-medium font-roboto text-white rounded-[100px]">
              Save & Continue
            </button>
          </div>
        </form>
      )}
      <FileUploadModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onFileSelect={handleFileSelect}
      />
    </>
  );
}

export default RegistrationDetailsForm;
