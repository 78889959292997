
import withAuth from "../../components/hoc/withAuth";

//Stepper form for the Personal details
import PatientStepperForm from "./profile_details_form/PatientStepperForm";

const PatientProfileDetails = () => {

  return (
    <>
      <div className="bg-[#F5FBF7] w-full lg:pt-24 lg:px-20 px-1 py-6">
        <PatientStepperForm/>
      </div>
    </>
  );
};

export default withAuth(PatientProfileDetails);
