import React, { useState } from 'react';

import { useAuth } from '../../../../../contexts/AuthContext';

//Steeping Forms
import ClinicDetails from './ClinicDetails';
import PracticeDetails from './PracticeDetails';
import TimeSlots from './TimeSlots';
import ProfileDetails from './ProfileDetails';

const StepperForm = () => {
  const {userData} = useAuth();
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };
  const stepDetails = {
    "Clinic details" : "Enter your clinic details",
    "Practice details": "Provide your practice details",
    "Time slots": "Set your available time slots",
    "Profile details": "Complete your profile information"
  }

  return (
  <>
      {/* header text for clinic details form in step 1 */}
      <div className={`mx-4 my-2 ${step === 1 ? "" : "hidden"}`}>
        <h1 className='text-4xl font-bold'>
          Welcome back, Dr.<span>{userData?.full_name}</span>
        </h1>
        <p className='text-base sm:mb-10'>
          We've verified your credentials. Now, let's set up your profile to help patients find you easily.
        </p>
      </div>
      {/* header text for time slot form in step 3 */}
      <div className={`mx-4 my-2 grid gap-3 ${step === 3 ? "" : "hidden"}`}>
        <h1 className='text-2xl font-medium lg:text-[40px] md:leading-[59.5px]'>
          Select your availability
        </h1>
        <p className='text-sm font-medium lg:text-lg sm:mb-10'>
          Set your time slots so that your patients see your availability
        </p>
      </div>

      <ol className="flex items-center justify-center w-full text-xs text-gray-900 font-medium sm:text-base">
        {Object.entries(stepDetails).map(([item, itemValue], idx) => (
          <li key={idx} className={`flex w-full ${idx === 0 ? "justify-center" : (idx === 1) ? "justify-center" : (idx === 2) ? "justify-center" : (idx === 3) ? "justify-center" : ""} relative text-gray-900 ${idx < 3 ? `after:content-[""] after:w-full after:h-0.5 after:${idx + 2 <= step ?'bg-light-primary':'bg-gray-200'} after:inline-block after:absolute lg:after:top-5 after:top-5 after:left-[45%]` : ''}`}>
            <div className="block w-16 sm:w-48 break-normal text-sm font-medium text-[#061D2E] text-center z-10">
              <span className={`w-10 h-10 ${idx + 2 <= step ? 'bg-light-primary text-white' : 'bg-[#DAE2FF] text-gray-900'} border-2 ${idx + 1 === step ? 'border-transparent' : 'border-gray-200'} rounded-full flex justify-center items-center mx-auto mb-3 text-sm lg:w-10 lg:h-10`}>
                {idx + 1}
              </span>
              <p className='text-base sm:font-bold'>
              {item}
              </p>
              <p className='text-xs w-52 hidden sm:grid text-center'>{itemValue}</p>
            </div>
          </li>
        ))}
      </ol>

      <div className="mt-4">
        
            <ClinicDetails step={step} nextStep={nextStep} />

        
            <PracticeDetails step={step} nextStep={nextStep} />
            
        
            <TimeSlots step={step} nextStep={nextStep} />
            
        
            <ProfileDetails step={step} />
      </div>
  </>
  );
};
  
export default StepperForm;