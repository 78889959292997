import {BrowserRouter, Routes, Route} from "react-router-dom"

import './App.css';

import ScrollToTop from "./utils/ScrollToTop";

// patient imports
import PatientLayout from "./pages/patient/layout/PatientLayout";
import Home from "./pages/patient/home/Home";
import Doctors from "./pages/patient/doctor/Doctors";
import BookAppointment from "./pages/patient/book_appointment/BookAppointment";
import ProfileDetails from "./pages/patient/confirm_appointment/ProfileDetails";
import Confirmation from "./pages/patient/confirm_appointment/Confirmation";
import CheckOut from "./pages/patient/confirm_appointment/CheckOut";

import PatientProfileDetails from "./pages/patient/patientDashboard/patient_profile/PatientProfileDetails";


// doctor imports
import DoctorLayout from "./pages/doctor/layout/DoctorLayout";
import DoctorHome from "./pages/doctor/home/Home";

// import TestQ from "./TestQ";

import DoctorDashBoardLayout from "./pages/doctorDashboard/layout/DoctorDashBoardLayout";

import RegistrationDetails from "./pages/doctorDashboard/doctorProfile/RegitrationPhase/RegistrationDetails";
import PersonalDetails from "./pages/doctorDashboard/doctorProfile/PersonalPhase/PersonalDetails";

import DoctorDashboard from "./pages/doctorDashboard/dashboard/DoctorDashboard";

// error page
import PageNotFound from "./pages/PageNotFound";


function App() {
  return (
    
    <BrowserRouter>
    <ScrollToTop>
      <div className="App font-inter">
        <div className="AppBody">
          <Routes>
              {/* routing for patient layout */}
              <Route path="/" element={<PatientLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/doctors" element={<Doctors />} />
                <Route path="/doctors/:dID/bookAppointment" element={<BookAppointment />} />
                <Route path="/confirmDetails" element={<ProfileDetails />} />
                <Route path="/confirmation" element={<Confirmation/>} />
                <Route path="/checkout" element={<CheckOut />} />

                <Route path="/patient/profile-details" element={<PatientProfileDetails/>}/>

              </Route>

              {/* Routing for doctor layouts (before a doctor logged in) */}
              <Route path="/doctor" element={<DoctorLayout />}>
                <Route path="home" element={<DoctorHome/>}/>
              </Route>

              {/* routing for doctor dashboard layouts (after a doctor logged in) */}
              <Route path="/doctor/dashboard" element={<DoctorDashBoardLayout />}>
                <Route path="" element={<DoctorDashboard />} />

                <Route path="profile/registration-details" element={<RegistrationDetails />} />
                <Route path="profile/personal-details" element={<PersonalDetails/>}/>
              </Route>

              {/* 404 page routings */} 
              <Route path="*" element={<PageNotFound/>}/>

              {/* other routings */} 
              {/* <Route path="/test" element={<TestQ/>}/> */}

            </Routes>
        </div>
      </div>
    </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
