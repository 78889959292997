import { useState, useEffect } from "react";
import { Outlet,useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";

import TopNav from "../components/base/TopNav";
import SideNav from "../components/base/SideNav";

const DoctorDashBoardLayout = () => {
  const navigate = useNavigate();
  const {isAuthenticated, isLoading, userData} = useAuth();

  const [isCompLoad, setIsCompLoad] = useState(false);

  useEffect(()=>{
      if(!isLoading){
          if(isAuthenticated){
              if(userData.hasOwnProperty("patient_id")){
                  navigate('/');
              }
              setIsCompLoad(true)
          } else {
            navigate('/doctor/home')
          }
      }
  },[isAuthenticated, isLoading, userData])

  return (
    <>
      {
        isCompLoad ? (
          <div className="flex flex-col lg:flex-grow-0 lg:flex-row">
            <TopNav />
            <SideNav />
            <Outlet />
          </div>
        ): (
          <p>loading ...</p>
        )
      }
      </>
  );
};

export default DoctorDashBoardLayout;
