import { Outlet,useNavigate } from "react-router-dom";

import { useAuth } from "../../../contexts/AuthContext";

import Navbar from "../components/base/Navbar";
import Footer from "../components/base/Footer";
import { useEffect } from "react";


export default function PatientLayout(){
    const navigate = useNavigate();
    const {isAuthenticated, isLoading, userData} = useAuth();

    useEffect(()=>{
        if(!isLoading){
            if(isAuthenticated){
                if(!userData.hasOwnProperty("patient_id")){
                    navigate('/doctor/home');
                }
            }
        }
    },[isAuthenticated, isLoading, userData])

    return(
        <section>
            <Navbar />

            <Outlet />

            <Footer />
        </section>
    );
}